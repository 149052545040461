// Adapted from window.google_tag_data.glBridge.generate()
export class GoogleAnalytics4Util {
    // Generates a _gl parameter for the given set of cookies expressed as a kvp object
    public static generateGl(cookies: object) {
        const parts = [];
        for (const cookieKey in cookies) {
          if (cookies.hasOwnProperty(cookieKey)) {
            const cookieValue = cookies[cookieKey];
            if (void 0 !== cookieValue && cookieValue === cookieValue && null !== cookieValue && "[object Object]" !== cookieValue.toString()) {
              parts.push(cookieKey, this.ga4Base64Encode(cookieValue));
            }
          }
        }
        const paramVal = parts.join("*");
        return ["1", this.addTimestamp(paramVal), paramVal].join("*");
      };

    // Psuedo base 64 encode using custom alphabet and removing prefix
    private static ga4Base64Encode(cookieValue: string): string {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.';
         // Remove version prefix (i.e. GA1.1.)
        cookieValue = cookieValue.substring(cookieValue.indexOf('.', cookieValue.indexOf('.')+1)+1);
        const results = [];
        for (let g = 0; g < cookieValue.length; g += 3) {
            let l = cookieValue.charCodeAt(g);
            const isLastValue = g + 1 < cookieValue.length; 
            let t = isLastValue ? cookieValue.charCodeAt(g + 1) : 0;
            const isNextToLast = g + 2 < cookieValue.length;
            let x = isNextToLast ? cookieValue.charCodeAt(g + 2) : 0;
            const ra = l >> 2;
            l = ((l & 3) << 4) | (t >> 4);
            t = ((t & 15) << 2) | (x >> 6);
            x &= 63;
            isNextToLast || ((x = 64), isLastValue || (t = 64));
            results.push(alphabet[ra], alphabet[l], alphabet[t], alphabet[x]);
        }
        return results.join("");
    }

    // Used for the magic hash stage in GA4
    private static MagicHashTable: any[];
    
    // Adds a timestamp and user hash to the given list of paramemters
    private static addTimestamp(paramVal: string): string {
        const n = (window as any).navigator;
        // Ensure magic hashtable is initialized
        if(!GoogleAnalytics4Util.MagicHashTable) {
            GoogleAnalytics4Util.MagicHashTable = Array(256);
            for (let c = 0; 256 > c; c++) {
                let d = c;
                for (let e = 0; 8 > e; e++)
                    d = d & 1 ? d >>> 1 ^ 3988292384 : d >>> 1;
                GoogleAnalytics4Util.MagicHashTable[c] = d
            }
        }

        // Prepend user info onto passed parameter data
        paramVal = [
            n.userAgent, 
            (new Date).getTimezoneOffset(), 
            n.userLanguage || n.language, 
            Math.floor((new Date(Date.now())).getTime() / 60 / 1E3), 
            paramVal
        ].join("*");

        // Magic hashing step
        let b = 4294967295; // 2^32-1
        for (let c = 0; c < paramVal.length; c++)
            b = b >>> 8 ^ GoogleAnalytics4Util.MagicHashTable[(b ^ paramVal.charCodeAt(c)) & 255];
        return ((b ^ -1) >>> 0).toString(36)
    }
}