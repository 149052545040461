
/**
 * Created by roman.kupin on 11/10/2017.
 */

 import {Guid} from 'shared';

export const PREFIX = "[ACCOUNT_API]";

export const OVERVIEW_FETCH = `${PREFIX}.OverviewFetch`;
export const OVERVIEW_FETCH_SUCCESS = `${OVERVIEW_FETCH}Success`;
export const OVERVIEW_FETCH_ERROR = `${OVERVIEW_FETCH}Error`;

export const PROPERTIES_FETCH = `${PREFIX}.PropertiesFetch`;
export const PROPERTIES_FETCH_SUCCESS = `${PROPERTIES_FETCH}Success`;
export const PROPERTIES_FETCH_ERROR = `${PROPERTIES_FETCH}Error`;

export const RANDOM_PHOTOS_FETCH = `${PREFIX}.RandomPhotosFetch`;
export const RANDOM_PHOTOS_FETCH_SUCCESS = `${RANDOM_PHOTOS_FETCH}Success`;
export const RANDOM_PHOTOS_FETCH_ERROR = `${RANDOM_PHOTOS_FETCH}Error`;

export const PROPERTY_STATISTICS_FETCH = `${PREFIX}.PropertiesStatistics`;
export const PROPERTY_STATISTICS_FETCH_SUCCESS = `${PROPERTY_STATISTICS_FETCH}Success`;
export const PROPERTY_STATISTICS_FETCH_ERROR = `${PROPERTY_STATISTICS_FETCH}Error`;


export function getOverview(startDate, endDate, properties) {
    return (dispatch, getState, {$http, environment, SpacesService}) => {
        dispatch({type: OVERVIEW_FETCH});
        const companyUuid = SpacesService.getCompanyUuid() || Guid.Empty;
        return $http.get(`${environment.apiBaseUrl}/${companyUuid}/companies/${companyUuid}/overview?StartDate=${startDate}&EndDate=${endDate}&properties=${properties}`)
            .then(response => {
                dispatch({type: OVERVIEW_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: OVERVIEW_FETCH_ERROR, payload: error});
                throw OVERVIEW_FETCH_ERROR;
            })
    }
}

export function getProperties(companyUUID, isShowAll = false) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: PROPERTIES_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${companyUUID}/companies/${companyUUID}/properties?isShowAll=${isShowAll}`)
            .then(response => {
                dispatch({type: PROPERTIES_FETCH_SUCCESS, payload: response.data});
                return response;
            }, error => {
                dispatch({type: PROPERTIES_FETCH_ERROR, payload: error});
                throw PROPERTIES_FETCH_ERROR;
            })
    }
}

export function getPropertiesStatistics(companyUUID, startDate, endDate) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: PROPERTY_STATISTICS_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${companyUUID}/companies/${companyUUID}/properties/statistics?StartDate=${startDate}&EndDate=${endDate}`)
            .then(response => {
                dispatch({type: PROPERTY_STATISTICS_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: PROPERTY_STATISTICS_FETCH_ERROR, payload: error});
                throw PROPERTY_STATISTICS_FETCH_ERROR;
            })
    }
}

export function getRandomPhotos(companyUUID, count = 5) {
    return (dispatch, getState, {$http, environment}) => {
        dispatch({type: RANDOM_PHOTOS_FETCH});
        return $http.get(`${environment.apiBaseUrl}/${companyUUID}/photos/random?count=${count}`)
            .then(response => {
                dispatch({type: RANDOM_PHOTOS_FETCH_SUCCESS, payload: response.data});
            }, error => {
                dispatch({type: RANDOM_PHOTOS_FETCH_ERROR, payload: error});
                throw RANDOM_PHOTOS_FETCH_ERROR;
            })
    }
}


export function getAdvocacyOverview() {

}

export function getAdvocacyGuestJourney() {

}

const All = {getOverview, getProperties, getPropertiesStatistics, getRandomPhotos, getAdvocacyOverview, getAdvocacyGuestJourney};
export default All;