import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PlaceholderPipe } from './pipes/placeholder.pipe';
import { MaxValidatorDirective } from './directives/max-validator.directive';
import { MinValidatorDirective } from './directives/min-validator.directive';
import { EditPanelComponent } from './components/edit-panel/edit-panel.component';
import { EditPanelsService } from './services/edit-panels.service';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { InputEditorComponent } from './components/editors/input-editor/input-editor.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { CustomEditorComponent } from './components/editors/custom-editor/custom-editor.component';
import { HtmlEditorComponent } from './components/editors/html-editor/html-editor.component';
import { WizardEditorComponent } from './components/editors/wizard-editor/wizard-editor.component';
import { CodeEditorComponent } from './components/editors/code-editor/code-editor.component';
import { SelectEditorComponent } from './components/editors/select-editor/select-editor.component';
import { TextHtmlEditorComponent } from './components/editors/text-html-editor/text-html-editor.component';
import { ToggleEditorComponent } from './components/editors/toggle-editor/toggle-editor.component';
import { ListEditorComponent } from './components/editors/list-editor/list-editor.component';
import { DaterangeEditorComponent } from './components/editors/daterange-editor/daterange-editor.component';
import { DateRangePickerModule } from '../date-range-picker';
import { RadioEditorComponent } from './components/editors/radio-editor/radio-editor.component';
import { CheckboxEditorComponent } from './components/editors/checkbox-editor/checkbox-editor.component';
import {
    DropzoneUploaderEditorComponent
} from './components/editors/dropzone-uploader-editor/dropzone-uploader-editor.component';
import { UploadersModule } from '../uploaders';
import { ModalEditorWrapperComponent } from './components/modal-editor-wrapper/modal-editor-wrapper.component';
import { TextareaEditorComponent } from './components/editors/textarea-editor/textarea-editor.component';
import { LoadingModule } from '../loading';
import { ImageLibraryEditorComponent } from './components/editors/image-library-editor/image-library-editor.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ImagesModule } from '../../images';
import { ColorEditorComponent } from './components/editors/color-editor/color-editor.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { ClickOutsideModule } from 'ng-click-outside';
import {
    MultipleInputEditorComponent
} from './components/editors/multiple-input-editor/multiple-input-editor.component';
import {
    MultipleInputListEditorComponent
} from './components/editors/multiple-input-list-editor/multiple-input-list-editor.component';
import { NgbDatepickerModule, NgbPopover, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AddressEditorComponent } from './components/editors/address-editor/address-editor.component';
import { StayLengthEditorComponent } from './components/editors/stay-length-editor/stay-length-editor.component';
import {
    BookableRangeEditorComponent
} from './components/editors/bookable-range-editor/bookable-range-editor.component';
import {
    ClosedDateRangesEditorComponent
} from './components/editors/closed-date-ranges-editor/closed-date-ranges-editor.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import {
    PeakOffSeasonsEditorComponent
} from './components/editors/peak-off-seasons-editor/peak-off-seasons-editor.component';
import { OccupancyEditor } from './components/editors/occupancy-editor/occupancy-editor.component';
import {
    MultipleTextareaEditorComponent
} from './components/editors/multiple-textarea-editor/multiple-textarea-editor.component';
import {
    BookingOptionTextEditorComponent
} from './components/editors/booking-option-text-editor/booking-option-text-editor.component';
import { ChildrenAgesEditorComponent } from './components/editors/children-ages-editor/children-ages-editor.component';
import { ScheduleEditorComponent } from './components/editors/schedule-editor/schedule-editor.component';
import { ListPipe } from './pipes/list.pipe';
import { ExcludeEditorComponent } from './components/editors/exclude-editor/exclude-editor.component';
import {
    ScheduleSimpleEditorComponent
} from './components/editors/schedule-simple-editor/schedule-simple-editor.component';
import {
    ExperiencesLicenseEditorComponent
} from './components/editors/experiences-license-editor/experiences-license-editor.component';
import { LanguageVariationsComponent } from './components/language-variations/language-variations.component';

@NgModule({
    declarations: [
        WizardEditorComponent,
        HtmlEditorComponent,
        CustomEditorComponent,
        InputEditorComponent,
        MultipleInputEditorComponent,
        MultipleInputListEditorComponent,
        CodeEditorComponent,
        SelectEditorComponent,
        PlaceholderPipe,
        MaxValidatorDirective,
        MinValidatorDirective,
        EditPanelComponent,
        TextHtmlEditorComponent,
        ToggleEditorComponent,
        ListEditorComponent,
        DaterangeEditorComponent,
        RadioEditorComponent,
        CheckboxEditorComponent,
        TextareaEditorComponent,
        DropzoneUploaderEditorComponent,
        ModalEditorWrapperComponent,
        ImageLibraryEditorComponent,
        ColorEditorComponent,
        AddressEditorComponent,
        StayLengthEditorComponent,
        BookableRangeEditorComponent,
        ClosedDateRangesEditorComponent,
        PeakOffSeasonsEditorComponent,
        OccupancyEditor,
        MultipleTextareaEditorComponent,
        ScheduleEditorComponent,
        ListPipe,
        ExcludeEditorComponent,
        BookingOptionTextEditorComponent,
        ChildrenAgesEditorComponent,
        ScheduleSimpleEditorComponent,
        ExperiencesLicenseEditorComponent,
        LanguageVariationsComponent
    ],
    imports: [
        CommonModule,
        LoadingModule,
        MatIconModule,
        NgSelectModule,
        MatSelectModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatDialogModule,
        ImagesModule,
        DateRangePickerModule,
        DatePickerModule,
        QuillModule.forRoot(),
        MonacoEditorModule,
        MatIconModule,
        FormsModule,
        UploadersModule,
        ColorChromeModule,
        ClickOutsideModule,
        NgbDatepickerModule,
        NgbTooltipModule,
        NgbPopover
    ],
    exports: [
        WizardEditorComponent,
        HtmlEditorComponent,
        CustomEditorComponent,
        InputEditorComponent,
        MultipleInputEditorComponent,
        MultipleInputListEditorComponent,
        CodeEditorComponent,
        SelectEditorComponent,
        NgSelectModule,
        MatSlideToggleModule,
        PlaceholderPipe,
        EditPanelComponent,
        TextHtmlEditorComponent,
        ToggleEditorComponent,
        ListEditorComponent,
        DaterangeEditorComponent,
        RadioEditorComponent,
        CheckboxEditorComponent,
        DropzoneUploaderEditorComponent,
        TextareaEditorComponent,
        ImageLibraryEditorComponent,
        ModalEditorWrapperComponent,
        ColorEditorComponent,
        AddressEditorComponent,
        StayLengthEditorComponent,
        BookableRangeEditorComponent,
        ClosedDateRangesEditorComponent,
        PeakOffSeasonsEditorComponent,
        OccupancyEditor,
        MultipleTextareaEditorComponent,
        ScheduleEditorComponent,
        ListPipe,
        ExcludeEditorComponent,
        BookingOptionTextEditorComponent,
        ChildrenAgesEditorComponent,
        ScheduleSimpleEditorComponent,
        ExperiencesLicenseEditorComponent,
        LanguageVariationsComponent
    ],
    providers: [
        EditPanelsService
    ]
})
export class EditorsModule {
}



