import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { HeaderComponent } from '../common/components/header/header.component';
import { InjectionToken } from '@angular/core';
import {
    AreasRegistry,
    BackgroundImageComponent,
    DaterangePickerComponent,
    EditPanelComponent,
    ImageLibraryEditorComponent,
    IntersectionObservableComponent,
    IntersectionObserverComponent,
    SelectEditorComponent,
    ToggleEditorComponent,
    TooltipComponent
} from 'shared';
import { SpacesComponent } from '../common/components/spaces/spaces.component';
import { MainNavComponent } from '../common/components/navigation/main-nav/main-nav.component';
import { ShortUrlListComponent } from 'account-hybrid/downgraded/short-urls/short-url-list/short-url-list.component';
import { PermissionsComponent } from 'account-hybrid/common/components/permissions/permissions.component';
import { CurationComponent } from 'account-hybrid/common/components/curation-modal/curation.component';
import { StickySpacerComponent } from '../../../../../libs/shared/src/lib/modules/sticky/sticky-spacer.component';

declare const angular;

export const AREAS_REGISTRY = new InjectionToken<AreasRegistry>('AreasRegistry');
export const APP_MENU_AREAS_REGISTRY = new InjectionToken<AreasRegistry>('AppMenuAreasRegistry');
// export const MOBILE_MENU_AREAS_REGISTRY = new InjectionToken<AreasRegistry>("MobileMenuAreasRegistry");
export const STATE_PROVIDER = new InjectionToken<AreasRegistry>('STATE_PROVIDER');

export function downgradeForAngularJS(environment) {
    angular
        .module('AccountApp.v2')
        .directive('ftHeader', downgradeComponent({ component: HeaderComponent }))
        .directive('ftTooltip', downgradeComponent({ component: TooltipComponent }))
        .directive('ftMainNav', downgradeComponent({ component: MainNavComponent }))
        .directive('ftSpaces', downgradeComponent({ component: SpacesComponent }))
        .directive('ftEditPanel', downgradeComponent({ component: EditPanelComponent }))
        .directive('ftSelectEditor', downgradeComponent({ component: SelectEditorComponent }))
        .directive('ftToggleEditor', downgradeComponent({ component: ToggleEditorComponent }))
        .directive('ftDaterangePicker', downgradeComponent({ component: DaterangePickerComponent }))
        .directive('ftImageLibraryEditor', downgradeComponent({ component: ImageLibraryEditorComponent }))
        .directive('ftShortUrlList', downgradeComponent({ component: ShortUrlListComponent }))
        .directive('ftCuration', downgradeComponent({ component: CurationComponent }))
        .factory('AreasRegistry', downgradeInjectable(AREAS_REGISTRY))
        .factory('AppMenuAreasRegistry', downgradeInjectable(APP_MENU_AREAS_REGISTRY));
    // .factory('MobileMenuAreasRegistry', downgradeInjectable(MOBILE_MENU_AREAS_REGISTRY));

    angular
        .module('AccountApp.v2')
        .config(['environmentProvider', function(environmentProvider) {
            environmentProvider.configure(environment);
        }]);


    downgradeDirective('ftBackgroundImage', { component: BackgroundImageComponent });
    downgradeDirective('ftIntersectionObserver', { component: IntersectionObserverComponent });
    downgradeDirective('ftIntersectionObservable', { component: IntersectionObservableComponent });
    downgradeDirective('ftPermissions', { component: PermissionsComponent });
    downgradeDirective('ftStickySpacer', { component: StickySpacerComponent });
}

function downgradeDirective(name, info) {
    angular.module('AccountApp.v2').directive(name, downgradeComponent(info));
    angular.module('AccountApp.v2').config([
        '$provide',
        $provide => {
            $provide.decorator(`${name}Directive`, [
                '$delegate',
                $delegate => {
                    $delegate[0].restrict = 'EA';
                    return $delegate;
                }
            ]);
        }
    ]);
}
