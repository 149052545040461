export interface PropertyUserDto {
    Email?: string;
    First?: string;
    FullName?: string;
    CompanyUserUuid?: string;
    Id?: string;
    Last?: string;
    id?: string;
}
export abstract class PropertyUserDtoExtensions {
    static getPropertyUserDtoKey(a: PropertyUserDto, defaultKey?: string) { 
        return (a?.Id || a?.id)?.toLowerCase() || defaultKey;
    }
}