import { CarouselItem } from './../../../../../../apps/account/src/app/common/components/touchpoints/models/certificate-email';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ft-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  @Input() items: CarouselItem[];
  constructor() { }

  select(item) {
    this.items.forEach((i) => { i.isVisible = false; });
    item.isVisible = true;
  };

  canGoLeft() {
    return this.items.findIndex(el => el.isVisible) > 0;
  };

  canGoRight() {
    return this.items.findIndex(el => el.isVisible) < this.items.length - 1;
  };

  left() {
    if (this.canGoLeft()) {
      let index = this.items.findIndex(el => el.isVisible);
      this.items[index].isVisible = false;
      index--;
      this.items[index].isVisible = true;
    }
  }

  right() {
    if (this.canGoRight()) {
      let index = this.items.findIndex(el => el.isVisible);
      this.items[index].isVisible = false;
      index++;
      this.items[index].isVisible = true;
    }
  }
}
