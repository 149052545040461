<ft-modal-wrapper>
    <ft-touchpoint-header [name]="'Discovery Bar'" [canActivate]="!!(selectedDiscoveryBarComponent$ | async)?.selector"
                          (activeToggled)="commit({isActive: $event})"
                          [isActive]="(selectedDiscoveryBarComponent$ | async)?.isActive">
    </ft-touchpoint-header>
    <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms" *ngIf="tabsShowed"
                   [(selectedIndex)]="selectedIndex">
        <mat-tab label="messaging">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form (commit)="commit({ messaging: $event?.messaging})" [loading$]="isLoading$"
                                      [commitSuccess$]="commitSuccess$">
                        <form ngForm>
                            <ng-container *ngIf="messaging">
                                <section class="ft2-block -small">
                                    <div class="ft2-container">
                                        <ng-container *ngIf="languages$ | async as languages">
                                            <ft-language-preview-panel [languages]="languages"
                                                                       *ngIf="languages.length > 1"
                                                                       [selectedLanguage]="selectedPreviewLanguage"
                                                                       (previewLanguageChange)="onPreviewLanguageChange($event)"
                                                                       [simplified]="true">
                                            </ft-language-preview-panel>
                                        </ng-container>
                                        <ft-multiple-input-editor title="Primary message"
                                                                  [validationDefinition]="[{type: 'text', maxLength: 250}]"
                                                                  [(ngModel)]="messaging"
                                                                  [fields]="[{name: 'headerText', label: '', languageName: 'languageName'}]"
                                                                  [name]="'messaging'">
                                            <ft-edit-panel label="Primary message">
                                                <div>
                                                    {{ messaging | languagePreview: 'headerText' : selectedPreviewLanguage?.languageCode | ftPlaceholder }}
                                                </div>
                                            </ft-edit-panel>
                                        </ft-multiple-input-editor>
                                    </div>
                                    <div class="ft2-container">
                                        <ft-multiple-input-editor title="Secondary message"
                                                                  [validationDefinition]="[{type: 'text', maxLength: 250}]"
                                                                  [(ngModel)]="messaging"
                                                                  [fields]="[{name: 'subHeaderText', label: '', languageName: 'languageName'}]"
                                                                  [name]="'messaging'">
                                            <ft-edit-panel label="Secondary message">
                                                <div>
                                                    {{ messaging | languagePreview: 'subHeaderText' : selectedPreviewLanguage?.languageCode | ftPlaceholder }}
                                                </div>
                                            </ft-edit-panel>
                                        </ft-multiple-input-editor>
                                    </div>
                                    <div class="ft2-container">
                                        <ft-multiple-input-editor title="Button label"
                                                                  [validationDefinition]="[{type: 'text', maxLength: 250}]"
                                                                  [(ngModel)]="messaging"
                                                                  [fields]="[{name: 'buttonText', label: '', languageName: 'languageName'}]"
                                                                  [name]="'messaging'">
                                            <ft-edit-panel label="Button label">
                                                <div>
                                                    {{ messaging | languagePreview: 'buttonText' : selectedPreviewLanguage?.languageCode | ftPlaceholder }}
                                                </div>
                                            </ft-edit-panel>
                                        </ft-multiple-input-editor>
                                    </div>
                                </section>
                            </ng-container>
                        </form>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="styling">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form (commit)="commit($event)" [loading$]="isLoading$"
                                      [commitSuccess$]="commitSuccess$">
                        <form ngForm>
                            <ng-container *ngIf="selectedDiscoveryBarComponent$ | async | clone as selectedComponent">
                                <section class="ft2-block -small">
                                    <div class="ft2-container">
                                        <ft-code-editor [language]="'css'" [title]="'CSS'" [height]="480" [name]="'css'"
                                                        [(ngModel)]="selectedComponent.css">
                                            <ft-edit-panel label="CSS" [loading]="isLoading$ | async">
                                                <div class="col-8"
                                                     [innerHTML]="selectedComponent.css | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-code-editor>
                                    </div>
                                </section>
                            </ng-container>
                        </form>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="integration">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form (commit)="commit($event)"
                                      *ngIf="selectedDiscoveryBarComponent$ | async | clone as selectedComponent"
                                      [loading$]="isLoading$"
                                      [commitSuccess$]="commitSuccess$">
                        <form ngForm>
                            <section class="ft2-block -small">
                                <div class="ft2-container">
                                    <ft-custom-editor title="Page location">
                                        <div content>
                                            <div class="ft2-form-group">
                                                <label>Inserting strategy</label>
                                                <ng-select [items]="domInjectionStrategyOptions"
                                                           [name]="'domInjectionStrategy'"
                                                           class="ft2-select -large mb-3" [bindLabel]="'label'"
                                                           [bindValue]="'value'"
                                                           [searchable]="false" [clearable]="false"
                                                           [(ngModel)]="selectedComponent.domInjectionStrategy">
                                                </ng-select>
                                            </div>
                                            <div class="ft2-form-group">
                                                <label>Target element(s)</label>
                                                <input class="ft2-input -large" [name]="'selector'" type="text"
                                                       placeholder="#elementID, .elementClassName, div[attribute='value']"
                                                       [(ngModel)]="selectedComponent.selector">
                                            </div>
                                            <div class="ft2-form-group">
                                                <label>Exclude element(s)</label>
                                                <input class="ft2-input -large" [name]="'antiSelector'" type="text"
                                                       placeholder="#elementID, .elementClassName, div[attribute='value']"
                                                       [(ngModel)]="selectedComponent.antiSelector">
                                            </div>
                                        </div>
                                        <ft-edit-panel label="Page location" [loading]="isLoading$ | async">
                                            <div>
                                                <div>
                                                    <span *ngIf="selectedComponent?.domInjectionStrategy != null">
                                                        {{ getLabel(selectedComponent.domInjectionStrategy) }}
                                                    </span>
                                                    <span *ngIf="selectedComponent?.selector">
                                                        {{ selectedComponent.selector }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span *ngIf="selectedComponent?.antiSelector">
                                                        Exclude {{ selectedComponent.antiSelector }}
                                                    </span>
                                                    <span
                                                        *ngIf="selectedComponent?.domInjectionStrategy == null && !selectedComponent?.selector">
                                                        Please provide value
                                                    </span>
                                                </div>
                                            </div>
                                        </ft-edit-panel>
                                    </ft-custom-editor>


                                    <!-- <ft-toggle-editor name="isSkipSummary"
                                                    [(ngModel)]="selectedComponent.isSkipSummary"
                                                    label="Status"
                                                    title="Should this discovery skip the plan summary"
                                                    summary="Discovery will allow users to skip the planner summary step when entering discovery through this component. Useful when plans are set via external triggers or forms.">
                                        <ft-edit-panel label="Should this discovery skip the plan summary" >
                                            {{ selectedComponent.isSkipSummary | ftOnOffToggle }}
                                        </ft-edit-panel>
                                    </ft-toggle-editor> -->
                                </div>
                            </section>
                        </form>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <ng-template #noData>
        <p class="mt-5 text-center">
            There is no data
        </p>
    </ng-template>
</ft-modal-wrapper>
