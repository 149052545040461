import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../date-utils';

@Pipe({
    name: 'isDateInList'
})
export class IsDateInListPipe implements PipeTransform {
    constructor() {
    }

    transform(date: Date, list: Date[]): boolean {
        if (date && list?.length) {
            return !!list.find(d => DateUtils.isSame(d, date));
        }
        return false;
    }
}
