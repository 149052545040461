import { NgModule } from '@angular/core';
import { PermissionsComponent } from "./permissions.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from '@flipto/shared';


@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        PermissionsComponent
    ],
    exports: [
        PermissionsComponent
    ]
})
export class PermissionsModule {

}
