<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <form class="ft2-input-group ft2-list-editor">
        <ng-select *ngIf="options"
                   [(ngModel)]="newValue"
                   [bindValue]="bindValue"
                   [bindLabel]="bindLabel"
                   (ngModelChange)="!!newValue && addItem()"
                   [hideSelected]="true"
                   [clearable]="false"
                   [items]="options"
                   [placeholder]="placeholder"
                   [name]="controlName"
                   class="ft2-select -no-arrow -large full-width">
        </ng-select>
        <input *ngIf="!options?.length" class="ft2-input -large"
               [(ngModel)]="newValue"
               [placeholder]="placeholder"
               [required]="required"
               [min]="min"
               [max]="max"
               [minlength]="minlength"
               [maxlength]="maxlength"
               [email]="email"
               [pattern]="pattern"
               [name]="controlName"
               #inputElement
               #input="ngModel"
               [type]="type">
        <div class="ft2-input-group_append">
            <button (click)="addItem()" type="submit" class="ft2-btn-primary" [disabled]="!newValue ||  input?.invalid">
                <mat-icon class="icon-xl">playlist_add</mat-icon>
            </button>
        </div>
    </form>
    <div class="ft2-form-error mt-2" *ngIf="!options?.length && input?.invalid && input?.touched || hasDuplicatesError">
        <p *ngIf="input?.errors?.required">
            Value is required.
        </p>
        <p *ngIf="input?.errors?.minlength">
            Value must be at least {{minlength}} characters long.
        </p>
        <p *ngIf="input?.errors?.maxlength">
            Value must be at most {{maxlength}} characters long.
        </p>
        <p *ngIf="input?.errors?.email">
            Value is not valid email address.
        </p>
        <p *ngIf="input?.errors?.pattern">
            {{patternValidationText ? patternValidationText : 'Value doesn not match pattern.' + pattern}}
        </p>
        <p *ngIf="input?.errors?.min">
            Value can't be less then {{min}}
        </p>
        <p *ngIf="input?.errors?.max">
            Value can't be more then {{max}}
        </p>
        <p *ngIf="hasDuplicatesError">
            Value already present in the list
        </p>
    </div>
    <div class="ft2-edit-dialog_modal -options-list mt-3 pb-1">
        <div class="mb-2 v-center" *ngFor="let text of value; let i = index">
            {{bindLabel ? text[bindLabel] : text}}
            <button class="ft2-btn-icon ml-1" (click)="removeItem(i)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</ft-modal-editor-wrapper>
