import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { PropertyUserDto } from '../models';
import { Environment, ENVIRONMENT_TOKEN } from '../../../core/environment.service';

@Injectable()
export class PropertyUserDataService extends EntityCollectionServiceBase<PropertyUserDto> {

    constructor(serviceElementaryFactory: EntityCollectionServiceElementsFactory,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
        super('PropertyUser', serviceElementaryFactory);
    }
}
