import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstantSearchComponent } from './instant-search/instant-search.component';
import { SharedModule } from "shared";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { Ng2SearchPipeModule } from "ng2-search-filter";

@NgModule({
    declarations: [
        InstantSearchComponent
    ],
    imports: [
        FormsModule,
        MatIconModule,
        CommonModule,
        SharedModule,
        Ng2SearchPipeModule
    ],
    exports: [
        InstantSearchComponent
    ]
})
export class InstantSearchModule {
}
