<div *ngIf="spacing" class="ft2-nav-spacing"></div>
<nav class="ft2 ft2-nav" [class.-sticky]="sticky">
    <!-- Spaces toggle -->
    <div class="ft2-nav_logo" *ngIf="logoVisible" [class.-large]="isCollaborativeSpace">
        <a (click)="spaceClicked()" *ngIf="spacesService.current?.icon">
            <img [src]="spacesService.current?.icon" [alt]="spacesService.current?.title">
            <!-- <small class="ft2-collaborative-badge mt-1" *ngIf="isCollaborativeSpace">
                collaborative
            </small> -->
        </a>
        <a class="ft2-flipto-badge" (click)="spaceClicked()" *ngIf="!spacesService.current?.icon">
        </a>
    </div>

    <ng-container *ngIf="menuVisible">
        <ng-container *ftCheckSpaceType="'Collaborative'">
            <ul class="ft2-nav-menu" *ftAreas="let areas=areas;let isActive=isActive;let invoke=invoke;">
                <ng-container *ngFor="let area of areas">
                    <li class="ft2-nav-menu_item" *ngIf="area.state || area.options || area.handler"
                        [ftDisabled]="area.isDisabled" [class]="area.$class">
                        <ng-container *ngIf="area.handler">
                            <a class="ft2-nav-menu-link" (click)="invoke($event, area.handler)">
                                {{area.name}}
                            </a>
                        </ng-container>
                        <!-- Primary -->
                        <ng-container *ngIf="area.state">
                            <a class="ft2-nav-menu-link" [ngClass]="{'-active': isActive(area)}" [uiSref]="area.state"
                               [uiParams]="area.stateParams" [uiSrefActive]="'-active'">{{area.name}}</a>
                        </ng-container>
                        <ng-container *ngIf="area.options">
                            <span class="ft2-nav-menu-link">
                                {{area.name}}
                            </span>
                            <div class="ft2-nav-sub">
                                <div class="ft2-nav-sub_popup">
                                    <ul class="ft2-nav-sub_vertical-menu">
                                        <li *ngFor="let option of area.options">
                                            <a *ngIf="option.state"
                                               [uiSref]="option.state"
                                               [uiParams]="option.stateParams">
                                                {{option.name}}
                                            </a>
                                            <a *ngIf="option.handler"
                                               (click)="invoke($event, option.handler)">
                                                {{option.name}}
                                            </a>
                                        </li>
                                        <li class="ft2-nav-sub_vertical-divider"
                                            *ngIf="isFliptoStaff && isCompanyContextAvailable">
                                            <ft-create-property>
                                                <a><i class="ft-icn-plus"></i> New property</a>
                                            </ft-create-property>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                    </li>
                </ng-container>
            </ul>
        </ng-container>


        <!--    <ng-container *ftCheckSpaceType="['Company', 'Mixed', 'Property']">-->
        <!--        <ul class="ft2-nav-menu">-->
        <!--            <li class="ft2-nav-menu_item" [uiSref]="'hub'" [uiSrefActive]="'-active'" [uiParams]="spaceAttributes" (mouseenter)="subMenuGroup.hideAll()">Overview</li>-->
        <!--            <li class="ft2-nav-menu_item" ftMenuLink #platformRef="ftMenuLink">Platform</li>-->
        <!--            &lt;!&ndash; with property context &ndash;&gt;-->
        <!--            &lt;!&ndash; <li *ftCheckSpaceType="['Mixed', 'Property']; operator:'ANY'" -->
        <!--                class="ft-navitem" [uiSref]="'stories.library'" -->
        <!--                [uiParams]="spaceAttributes" -->
        <!--                (mouseenter)="subMenuGroup.hideAll()">Library</li>-->
        <!--            <li *ftCheckSpaceType="['Mixed', 'Property']; operator:'ANY'" -->
        <!--                class="ft-navitem" -->
        <!--                [uiSref]="'people.directory'" [uiParams]="spaceAttributes" -->
        <!--                (mouseenter)="subMenuGroup.hideAll()">People</li> &ndash;&gt;-->
        <!--            &lt;!&ndash; without property context &ndash;&gt;-->
        <!--            <li class="ft2-nav-menu_item" [uiSref]="'stories.library'" [uiParams]="spaceAttributes" (mouseenter)="subMenuGroup.hideAll()">Library</li>-->
        <!--            <li class="ft2-nav-menu_item" [uiSref]="'people.directory'" [uiParams]="spaceAttributes" (mouseenter)="subMenuGroup.hideAll()">People</li>-->
        <!--        </ul>-->

        <!--        <ft-sub-menu-group #subMenuGroup="ftSubMenuGroup">-->
        <!--            <ft-sub-menu [menuLinkRef]="platformRef" [modifier]="'platform-features'">-->
        <!--                <div class="content">-->
        <!--                    <div [uiSref]="'advocacy.big-picture'" [uiParams]="spaceAttributes">-->
        <!--                        <h3>Advocacy</h3>-->
        <!--                        <span class="tiny-text">Reach people during the dreaming phase and inspire a new audience to-->
        <!--                            travel to your-->
        <!--                            destination.</span>-->
        <!--                    </div>-->
        <!--                    <div [uiSref]="'discovery'" [uiParams]="spaceAttributes">-->
        <!--                        <h3>Discovery</h3>-->
        <!--                        <span class="tiny-text">Win visitors over during the planning phase by helping them discover-->
        <!--                            you're the-->
        <!--                            perfect fit for their travel plans.</span>-->
        <!--                    </div>-->
        <!--                    &lt;!&ndash; <div>-->
        <!--                        <h3>Collaboratives</h3>-->
        <!--                        <span class="tiny-text">Work together with other organizations to build relationships that-->
        <!--                            improve travel-->
        <!--                            for everyone.</span>-->
        <!--                    </div> &ndash;&gt;-->
        <!--                </div>-->
        <!--                <div class="footer">-->
        <!--                    <ul>-->
        <!--                        <li *ftCheckSpaceType="['Property', 'Mixed']" [uiSref]="'mission-control.details'" [uiParams]="spaceAttributes"><a>Mission Control</a></li>-->
        <!--                        <li *ftCheckSpaceType="['Property', 'Mixed']" [uiSref]="'incentives'" [uiParams]="spaceAttributes"><a>Incentives</a></li>-->
        <!--                        <li [uiSref]="'reports'" [uiParams]="spaceAttributes"><a>Reports</a></li>-->
        <!--                        <li *ftCheckSpaceType="['Company', 'Mixed', 'Collaborative']" [uiSref]="'team'" [uiParams]="spaceAttributes"><a>Team</a></li>-->
        <!--                        <li *ftCheckSpaceType="['Company', 'Mixed']" [uiSref]="'account-settings'" [uiParams]="spaceAttributes"><a>Account Settings</a></li>-->
        <!--                        <li *ftCheckSpaceType="['Company', 'Mixed']" [uiSref]="'domains'" [uiParams]="spaceAttributes"><a>Domains</a></li>-->
        <!--                        <li *ftCheckSpaceType="['Company', 'Mixed']" ftPermissions="'FliptoAdministration'">-->
        <!--                            <a [ftTargetsModal]="'modals.property.create'">-->
        <!--                                <i class="ft-icn-plus"></i> New property-->
        <!--                            </a>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </div>-->
        <!--            </ft-sub-menu>-->
        <!--        </ft-sub-menu-group>-->
        <!--    </ng-container>-->
        <ng-container *ftCheckSpaceType="['Company', 'Mixed', 'Property']">
            <ul class="ft2-nav-menu">
                <li class="ft2-nav-menu_item">
                    <a class="ft2-nav-menu-link" [uiSref]="'hub'" [uiSrefActive]="'-active'"
                       [uiParams]="spaceAttributes">
                        Overview
                    </a>
                </li>
                <li class="ft2-nav-menu_item -align-sub">
                    <span class="ft2-nav-menu-link">
                        Platform
                    </span>
                    <div class="ft2-nav-sub">
                        <div class="ft2-nav-sub_popup">
                            <ul class="ft2-nav-sub_primary-menu text-center">
                                <li [uiSref]="'advocacy.big-picture'" [uiParams]="spaceAttributes">
                                    <img [src]="'assets/img/feature-icons/ft-appicon-advocacy.svg' | ftFilePath" alt="">
                                    <p class="h5 mb-3">Advocacy</p>
                                    <p>
                                        Inspire an audience of dreamers.
                                    </p>
                                </li>
                                <li [uiSref]="'discovery'" [uiParams]="spaceAttributes">
                                    <!-- <small class="ft2-badge">new</small> -->
                                    <img [src]="'assets/img/feature-icons/ft-appicon-discovery.svg' | ftFilePath"
                                         alt="">
                                    <p class="h5 mb-3">Discovery</p>
                                    <p>
                                        Unveil and win over planners.
                                    </p>
                                </li>
                                <!--                                <li>-->
                                <!--                                    <small class="ft2-tooltip">coming soon</small>-->
                                <!--                                    <img [src]="'assets/img/feature-icons/ft-appicon-collaborative.svg' | ftFilePath" alt="">-->
                                <!--                                    <p class="h5 mb-1">Collaboratives</p>-->
                                <!--                                    <p>-->
                                <!--                                        Level up with your community-->
                                <!--                                    </p>-->
                                <!--                                </li>-->
                            </ul>
                            <ul class="ft2-nav-sub_bottom-menu">
                                <li *ftCheckSpaceType="['Property', 'Mixed']" [uiSref]="'mission-control.details'"
                                    [uiParams]="spaceAttributes">
                                    <a>Mission Control</a>
                                </li>
                                <li *ftCheckSpaceType="['Property', 'Mixed']" [uiSref]="'incentives'"
                                    [uiParams]="spaceAttributes">
                                    <a>Incentives</a>
                                </li>
                                <li [uiSref]="'reports'" [uiParams]="spaceAttributes">
                                    <a>Reports</a>
                                </li>
                                <li *ftCheckSpaceType="['Company', 'Mixed', 'Collaborative']" [uiSref]="'team'"
                                    [uiParams]="spaceAttributes">
                                    <a>Team</a>
                                </li>
                                <li *ftCheckSpaceType="['Company', 'Mixed']" [uiSref]="'domains'"
                                    [uiParams]="spaceAttributes">
                                    <a>Domains</a>
                                </li>
                                <li *ftCheckSpaceType="['Company', 'Mixed']" [uiSref]="'account-settings'" [uiParams]="spaceAttributes">
                                    <a>Account Settings</a>
                                </li>
                                <li *ftCheckSpaceType="['Company', 'Mixed']" ftPermissions="'FliptoAdministration'">
                                    <ft-create-property>
                                        <a><i class="ft-icn-plus"></i> New property</a>
                                    </ft-create-property>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <!-- with property context -->
                <!-- <li *ftCheckSpaceType="['Mixed', 'Property']; operator:'ANY'"
                    class="ft-navitem" [uiSref]="'stories.library'"
                    [uiParams]="spaceAttributes"
                    (mouseenter)="subMenuGroup.hideAll()">Library</li>
                <li *ftCheckSpaceType="['Mixed', 'Property']; operator:'ANY'"
                    class="ft-navitem"
                    [uiSref]="'people.directory'" [uiParams]="spaceAttributes"
                    (mouseenter)="subMenuGroup.hideAll()">People</li> -->
                <!-- without property context -->
                <li class="ft2-nav-menu_item" *ftCheckSpaceType="['Property', 'Mixed']">
                    <span *ngIf="isLocalLicenseActive() || isOGLicenseActive()"
                       class="ft2-nav-menu-link">
                        Stories
                    </span>
                    <a class="ft2-nav-menu-link"
                       *ngIf="!isLocalLicenseActive() && !isOGLicenseActive()"
                       uiSref="stories.library"
                       [uiParams]="spaceAttributes"
                       uiSrefActive="-active">
                        Stories
                    </a>
                    <div class="ft2-nav-sub" *ftCheckLicense="'Experiences'">
                        <div class="ft2-nav-sub_popup">
                            <ul class="ft2-nav-sub_card-menu ft2-card-menu">
                                <li class="ft2-card-menu_item"
                                    [uiSref]="'stories.library'"
                                    [uiParams]="spaceAttributes"
                                    [ftBackgroundImage]="'assets/img/backgrounds/stories-menu-card.png' | ftFilePath">
                                    <p class="h6 bold-text">Traveler stories</p>
                                </li>
                                <li class="ft2-card-menu_item"
                                    *ngIf="isFliptoStaff"
                                    [uiSref]="'experiences.org'"
                                    [uiParams]="spaceAttributes"
                                    [ftBackgroundImage]="property?.BackgroundImageUrl || company?.BackgroundImageUrl">
                                    <div class="text-center">
                                        <p class="solid-text medium-text"> Experiences At</p>
                                        <p class="h6 bold-text">{{ company?.Name || property?.Name }}</p>
                                    </div>
                                </li>
                                <li class="ft2-card-menu_item"
                                    *ngIf="isOGLicenseActive() && isFliptoStaff"
                                    [uiSref]="'experiences.vendor'"
                                    [uiParams]="spaceAttributes"
                                    [ftBackgroundImage]="'assets/img/backgrounds/og-menu-card.png' | ftFilePath">
                                    <div class="text-center">
                                        <p class="solid-text medium-text"> Local events </p>
                                        <img width="200px" src="assets/img/logos/og-logo-light.png" alt="">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="ft2-nav-menu_item" *ftCheckSpaceType="['Property', 'Mixed']">
                    <a class="ft2-nav-menu-link" [uiSref]="'people.directory'" [uiParams]="spaceAttributes">
                        People
                    </a>
                    <!--                <div class="ft2-nav-sub">-->
                    <!--                    <div class="ft2-nav-sub_popup">-->
                    <!--                        <ul class="ft2-nav-sub_primary-menu">-->
                    <!--                            <li>-->
                    <!--                                <p class="h5 mb-1">Audiences</p>-->
                    <!--                                <p>-->
                    <!--                                    Every traveler and prospect you’ve had conversations with in the dreaming, planning,-->
                    <!--                                    and experiencing phases.-->
                    <!--                                </p>-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                <p class="h5 mb-1">Collaborators</p>-->
                    <!--                                <p>-->
                    <!--                                    Every advocate, team member, and local who is helping you to reach and convert new-->
                    <!--                                    audiences.-->
                    <!--                                </p>-->
                    <!--                            </li>-->
                    <!--                        </ul>-->
                    <!--                        <p class="solid-text muted-text small-text text-center">-->
                    <!--                            Explore people by segments-->
                    <!--                        </p>-->
                    <!--                        <ul class="ft2-nav-sub_secondary-menu">-->
                    <!--                            <li>-->
                    <!--                                Guest-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                Site visitors-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                Groups-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                Social-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                Locals-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                Team-->
                    <!--                            </li>-->
                    <!--                        </ul>-->
                    <!--                    </div>-->
                    <!--                </div>-->
                </li>
            </ul>
        </ng-container>
    </ng-container>


    <ft-app-menu-entry *ngIf="sideMenuVisible"></ft-app-menu-entry>
</nav>
<ft-app-menu *ngIf="sideMenuVisible"></ft-app-menu>
