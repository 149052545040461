import { RRule, rrulestr } from 'rrule';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

export class RRuleUtil {
    defaultTranslations = {
        en: {
            "flipto.discovery.multipleDates": "{{number}} dates",
            "flipto.discovery.everyWeekday": "Every {{weekday}}",
            "flipto.discovery.multipleDaysAWeek": "{{number}} days a week",
            "flipto.discovery.everyDay": "Every day",
            "flipto.discovery.everyWeekdays": "Weekdays",
            "flipto.discovery.everyWeekends": "Weekends",
            "flipto.discovery.first": "first",
            "flipto.discovery.second": "second",
            "flipto.discovery.third": "third",
            "flipto.discovery.fourth": "fourth",
            "flipto.discovery.fifth": "fifth",
            "flipto.discovery.last": "last",
            "flipto.discovery.everyNWeekday": "{{n}} {{weekday}}s",
            "flipto.discovery.everyNLastWeekday": "{{n}} last {{weekday}}s",
            "flipto.discovery.everyNWeekdays": "{{n}} weekdays",
            "flipto.discovery.everyNLastWeekdays": "{{n}} last weekdays",
            "flipto.discovery.everyNWeekends": "{{n}} weekends",
            "flipto.discovery.everyNLastWeekends": "{{n}} last weekends",
        }
    };
    mockTranslateService: Partial<TranslateService> = {
        instant: (key: string, interpolateParams?: Record<string, string>) => {
            let match: string = this.defaultTranslations.en[key] || key;
            Object.keys(interpolateParams || {}).forEach(p => {
                match = match.replace(`{{${p}}}`, interpolateParams[p]);
            });
            return match;
        }
    };

    constructor(private date: { transform(value: any, pattern: string): any }, private translateService?: Partial<TranslateService>) {
        if (!this.translateService) {
            this.translateService = this.mockTranslateService;
        }
    }

    transform(value: string, fallbackValue?: string): any {
        let res = fallbackValue;
        if (value) {
            // Parse RRule string in to object
            const rrule = rrulestr(value);

            switch (rrule.options.freq) {
                case RRule.YEARLY:
                    if (rrule.options.bymonth?.length && rrule.options.bymonthday?.length) {
                        res = this.getYearDate(rrule.options.bymonth[0], rrule.options.bymonthday[0]);
                    }
                    break;
                case RRule.WEEKLY:
                    const rruleWeekdays = rrule.options.byweekday || [];
                    if (rruleWeekdays.length === 1) {
                        // Ex. EVERY MON
                        res = this.translateRRuleWeekday(rruleWeekdays[0]);
                    } else {
                        if (this.isWeekends(rruleWeekdays)) {
                            // Ex. EVERY WEEKENDS
                            res = this.translateRRuleWeekends();
                            break;
                        }
                        if (this.isWeekdays(rruleWeekdays)) {
                            // Ex. EVERY WEEKDAYS
                            res = this.translateRRuleWeekdays();
                            break;
                        }
                        if (this.isEveryDay(rruleWeekdays)) {
                            // Ex. EVERY DAY
                            res = this.translateRRuleEveryDay();
                            break;
                        }
                        if (this.isMultipleWeekdays(rruleWeekdays)) {
                            // Ex. # DAYS
                            res = this.translateRRuleMultipleDaysAWeek(rruleWeekdays);
                            break;
                        }
                        // Ex. EVERY MON & FRI ...
                        res = this.joinWeekdays([
                            this.translateRRuleWeekday(rruleWeekdays[0]),
                            ...rruleWeekdays.slice(1).map(wd => this.getWeekdayShortName(wd)),
                        ]);
                    }
                    break;
                case RRule.MONTHLY:
                    const rruleNWeekdays = rrule.options.bynweekday || [];
                    if (rruleNWeekdays.length === 1) {
                        // Ex. SECOND MONDAYS
                        res = this.translateRRuleNWeekday(rruleNWeekdays[0]);
                    } else {
                        if (this.isNWeekends(rruleNWeekdays)) {
                            // Ex. SECOND WEEKENDS
                            res = this.translateRRuleNWeekends(rruleNWeekdays[0][1]);
                            break;
                        }
                        if (this.isNWeekdays(rruleNWeekdays)) {
                            // Ex. SECOND WEEKDAYS
                            res = this.translateRRuleNWeekdays(rruleNWeekdays[0][1]);
                            break;
                        }
                        if (!this.isMultipleNWeekdays(rruleNWeekdays)) {
                            // Ex. SECOND MONDAYS & SECOND FRIDAYS ...
                            res = rruleNWeekdays.map(nwd => this.translateRRuleNWeekday(nwd)).join(' & ');
                        }
                    }
            }
        }

        return res;
    }

    isWeekdays(weekdays: number[]) {
        return weekdays.length === 5 && !weekdays.includes(5) && !weekdays.includes(6);
    }

    isWeekends(weekdays: number[]) {
        return weekdays.length === 2 && weekdays.includes(5) && weekdays.includes(6);
    }

    isMultipleWeekdays(weekdays: number[]) {
        return weekdays.length > 3;
    }

    isEveryDay(weekdays: number[]) {
        return weekdays.length === 7;
    }

    isNWeekdays(nWeekdays: number[][]) {
        return nWeekdays.length === 5 && nWeekdays.every((nwd, index, arr) => nwd[0] !== 5 && nwd[0] !== 6 && nwd[1] === arr[0][1]);
    }

    isNWeekends(nWeekdays: number[][]) {
        return nWeekdays.length === 2 && nWeekdays.find(nwd => nwd[0] === 5 || nwd[0] === 6) && nWeekdays[0][1] === nWeekdays[1][1];
    }

    isMultipleNWeekdays(nWeekdays: number[][]) {
        return nWeekdays.length > 2;
    }

    translateRRuleWeekday(weekdayOrder: number) {
        // Ex. EVERY MON
        return this.translateService.instant('flipto.discovery.everyWeekday', {
            weekday: this.getWeekdayShortName(weekdayOrder),
        });
    }

    translateRRuleMultipleDaysAWeek(weekdays: number[]) {
        return this.translateService.instant('flipto.discovery.multipleDaysAWeek', {
            number: weekdays.length,
        });
    }

    translateRRuleEveryDay() {
        return this.translateService.instant('flipto.discovery.everyDay');
    }

    translateRRuleWeekends() {
        return this.translateService.instant('flipto.discovery.everyWeekends');
    }

    translateRRuleWeekdays() {
        return this.translateService.instant('flipto.discovery.everyWeekdays');
    }

    translateRRuleNWeekday(nWeekday: number[]) {
        const number = nWeekday[1];
        const weekdayName = this.getWeekdayName(nWeekday[0]);
        const isLast = number < 0;
        let trPath;
        let numberTr = this.getRRuleNWeekdayNumberTranslation(number);
        if (Math.abs(number) === 1) {
            // Specific not mirror case: 'FIRST MONDAYS' - 'LAST MONDAYS'
            trPath = 'flipto.discovery.everyNWeekday';
        } else {
            // Mirror cases 2 - 5:
            // 'SECOND MONDAYS'  -  'SECOND LAST MONDAYS'
            // 'THIRD MONDAYS'  -  'THIRD LAST MONDAYS'
            // ...
            trPath = isLast ? 'flipto.discovery.everyNLastWeekday' :'flipto.discovery.everyNWeekday';
        }
        return this.translateService.instant( trPath, { n: numberTr, weekday: weekdayName });
    }

    translateRRuleNWeekends(number: number) {
        const isLast = number < 0;
        let trPath;
        let numberTr = this.getRRuleNWeekdayNumberTranslation(number);
        if (Math.abs(number) === 1) {
            // Specific not mirror case: 'FIRST WEEKENDS' - 'LAST WEEKENDS'
            trPath = 'flipto.discovery.everyNWeekends';
        } else {
            // Mirror cases 2 - 5:
            // 'SECOND WEEKENDS'  -  'SECOND LAST WEEKENDS'
            // 'THIRD WEEKENDS'  -  'THIRD LAST WEEKENDS'
            // ...
            trPath = isLast ? 'flipto.discovery.everyNLastWeekends' :'flipto.discovery.everyNWeekends';
        }
        return this.translateService.instant( trPath, { n: numberTr });
    }

    translateRRuleNWeekdays(number: number) {
        const isLast = number < 0;
        let trPath;
        let numberTr = this.getRRuleNWeekdayNumberTranslation(number);
        if (Math.abs(number) === 1) {
            // Specific not mirror case: 'FIRST WEEKDAYS' - 'LAST WEEKDAYS'
            trPath = 'flipto.discovery.everyNWeekdays';
        } else {
            // Mirror cases 2 - 5:
            // 'SECOND WEEKDAYS'  -  'SECOND LAST WEEKDAYS'
            // 'THIRD WEEKDAYS'  -  'THIRD LAST WEEKDAYS'
            // ...
            trPath = isLast ? 'flipto.discovery.everyNLastWeekdays' :'flipto.discovery.everyNWeekdays';
        }
        return this.translateService.instant( trPath, { n: numberTr });
    }

    getRRuleNWeekdayNumberTranslation(number: number) {
        const isLast = number < 0;
        // Handle translations for number of weekday in month
        // Max. is 5
        let numberTr;
        switch (Math.abs(number)) {
            case 1:
                numberTr = this.translateService.instant( isLast ? 'flipto.discovery.last' :'flipto.discovery.first');
                break;
            case 2:
                numberTr = this.translateService.instant( 'flipto.discovery.second');
                break;
            case 3:
                numberTr = this.translateService.instant( 'flipto.discovery.third');
                break;
            case 4:
                numberTr = this.translateService.instant( 'flipto.discovery.fourth');
                break;
            case 5:
                numberTr = this.translateService.instant( 'flipto.discovery.fifth');
                break;
        }
        return numberTr;
    }

    getWeekdayShortName(weekdayOrder: number) {
        // Transform weekday order in to weekday short name:
        // 0 -> MON
        // 1 -> TUE
        // 2 -> WED
        // ...
        return this.date.transform(
            this.adjustDateToWeekday(weekdayOrder + 1),
            'EEE',
        );
    }

    getWeekdayName(weekdayOrder: number) {
        // Transform weekday order in to weekday name:
        // 0 -> MONDAY
        // 1 -> TUESDAY
        // 2 -> WEDNESDAY
        // ...
        return this.date.transform(
            this.adjustDateToWeekday(weekdayOrder + 1),
            'EEEE',
        );
    }

    getYearDate(monthNumber: number, monthdayNumber: number) {
        const date = new Date();
        date.setMonth(monthNumber - 1, monthdayNumber);
        return  this.date.transform(
            date,
            'MMM d',
        )
    }

    adjustDateToWeekday(weekdayNumber: number) {
        const currentDate = new Date();
        const currentDayOfWeek = currentDate.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

        // Calculate the difference between the current day and the desired weekday
        const diff = weekdayNumber - currentDayOfWeek;

        // Adjust the date by adding the difference
        currentDate.setDate(currentDate.getDate() + diff);

        return currentDate;
    }

    // Join weekdays with ', ' and ' & ' for last
    // Ex. EVERY MON, TUE & FRI
    joinWeekdays(weekdays: string[]): string {
        if (weekdays.length === 0) {
            return '';
        } else if (weekdays.length === 1) {
            return weekdays[0];
        } else {
            let last = weekdays.pop();
            return weekdays.join(', ') + ' & ' + last;
        }
    }

    static getNextOccurrence(value: string, startDate?: Date): Date {
        if (value) {
            const options = RRule.parseString(value);
            const newRrule = new RRule({
                ...options,
                dtstart: startDate || new Date(),
                count: 1,
            });
            const dates = newRrule.all();
            return dates[0];
        }
        return null;
    }

    static getLastOccurrence(value: string, endDate?: Date): Date {
        if (value) {
            const options = RRule.parseString(value);
            const newRrule = new RRule({
                ...options,
                until: endDate || new Date(),
                count: 1,
            });
            const dates = newRrule.all();
            return dates[0];
        }
        return null;
    }

    static getAllOccurrence(value: string, startDate?: string | Date, endDate?: string | Date): Date[] {
        if (value) {
            const options = RRule.parseString(value);
            const newRrule = new RRule({
                ...options,
                dtstart: startDate ? new Date(startDate) : new Date(),
                until: endDate ? new Date(endDate) : dayjs().add(2, 'year').toDate(),
            });
            return newRrule.all();
        }
        return [];
    }
}
