import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { notificationsReducer } from "./store";
import { NotificationsEffects } from "./store/notifications.effects";
import { NotificationsService } from "./services/notifications.service";
import { SharedModule } from 'shared';


@NgModule({
    declarations: [
        NotificationsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature('Notifications', notificationsReducer),
        EffectsModule.forFeature([NotificationsEffects]),
    ],
    exports: [
        NotificationsComponent
    ],
    providers: [
        NotificationsService
    ]
})
export class NotificationsModule {
}
