<ft-modal-wrapper>
    <ft-touchpoint-header [name]="'Discovery Router'" [activeToggleEnabled]="false">
    </ft-touchpoint-header>

    <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms"
                   [(selectedIndex)]="selectedIndex">
        <mat-tab label="Styling">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ng-container *ngIf="discoverySettings$ | async | clone as settings">
                        <ft-snapshot-form
                            (commit)="commit({stylingViewModel: $event})"
                            [loading$]="isLoading$"
                            [commitSuccess$]="commitSuccess$">
                            <section class="ft2-block -small">
                                <div class="ft2-container">
                                    <form ngForm>
<!--                                        <ft-select-editor title="Theme"-->
<!--                                                          [(ngModel)]="settings.stylingViewModel.routerTheme"-->
<!--                                                          [placeholder]="'Select theme'"-->
<!--                                                          name="routerTheme"-->
<!--                                                          [bindLabel]="'name'"-->
<!--                                                          [bindValue]="'value'"-->
<!--                                                          [options]="discoveryRouterThemeOptions$ | async">-->
<!--                                            <ft-edit-panel label="Router theme">-->
<!--                                                <ng-container *ngFor="let theme of discoveryRouterThemeOptions$ | async">-->
<!--                                                    <ng-container-->
<!--                                                        *ngIf="settings.stylingViewModel.routerTheme == theme?.value">-->
<!--                                                        {{ theme?.name | ftPlaceholder }}-->
<!--                                                    </ng-container>-->
<!--                                                </ng-container>-->
<!--                                            </ft-edit-panel>-->
<!--                                        </ft-select-editor>-->
                                        <ft-code-editor
                                            language="css"
                                            title="CSS"
                                            name="routerCss"
                                            [(ngModel)]="settings.stylingViewModel.routerCss">
                                            <ft-edit-panel
                                                label="CSS"
                                                [loading]="isLoading$ | async"
                                                [error]="error$ | async">
                                                <div class="col-8"
                                                     [innerHTML]="settings.stylingViewModel?.routerCss | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-code-editor>
                                    </form>
                                </div>
                            </section>
                        </ft-snapshot-form>
                    </ng-container>
                </ft-permissions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ft-modal-wrapper>
