import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnapshotFormComponent } from './components/snapshot-form/snapshot-form.component';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { PendingChangesComponent } from './components/pending-changes/pending-changes.component';
import { ValidationChecklistModule } from '../validation-checklist';
import { SubmitConfirmPanelComponent } from './components/submit-confirm-panel/submit-confirm-panel.component';
import { StickyModule } from '../../modules/sticky';


@NgModule({
    declarations: [
        SnapshotFormComponent,
        PendingChangesComponent,
        SubmitConfirmPanelComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        LoadingModule,
        ValidationChecklistModule,
        StickyModule
    ],
    exports: [
        SnapshotFormComponent,
        PendingChangesComponent,
        SubmitConfirmPanelComponent
    ]
})
export class SnapshotModule {
}
