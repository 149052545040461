import { NgModule } from "@angular/core";
import { ShortUrlListComponent } from './short-url-list/short-url-list.component';
import { ShortLinkCodeUniqueValidatorDirective } from './short-url-wizard/short-link-code-unique-validator';
import { ShortLinkNameUniqueValidatorDirective } from './short-url-wizard/short-link-name-unique-validator';
import { ShortUrlWizardComponent } from './short-url-wizard/short-url-wizard.component';
import { ShortUrlService } from './short-urls.service';
import { CommonModule } from '@angular/common';
import { AccountCommonModule } from 'account-hybrid/common/common.module';
import { SharedModule } from 'shared';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ShortLinkCodeUniqueValidatorDirective,
        ShortLinkNameUniqueValidatorDirective,
        ShortUrlListComponent,
        ShortUrlWizardComponent
    ],
    providers: [
        ShortUrlService
    ],
    imports: [
        FormsModule,
        CommonModule,
        AccountCommonModule,
        SharedModule
    ],
    exports: [
        ShortLinkCodeUniqueValidatorDirective,
        ShortLinkNameUniqueValidatorDirective,
        ShortUrlListComponent,
        ShortUrlWizardComponent
    ]
})
export class ShortUrlsModule {

}