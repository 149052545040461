import {
    DataCorrect,
    DataOk,
    DataError,
    ModelOk,
    ModelCorrected,
    ModelError,
    ModelValidationResponse, ProcessingStatus, ErrorModelValidationResponse
} from './response';

const pIf = require('p-if');
const pPipe = require('p-pipe');

export class DataProcessingPipeFactory {

    static CreateUpdateValidationPipe(validateFn: (...args) => boolean) {
        return pPipe(
            pIf(
                (signal) => validateFn(signal),
                () => DataOk(),
                () => DataError()
            )
        );
    }

    static CreateModelValidationPipe(validateFn: (...args) => ModelValidationResponse,
                                     correctFn?: (...args) => void,
                                     validateIfFn?: (...args) => boolean) {
        return pPipe(
            pIf(
                (model: any) => {
                    if (!!validateIfFn) {
                        return validateIfFn(model) && validateFn(model).status === ProcessingStatus.Ok;
                    }
                    return validateFn(model).status === ProcessingStatus.Ok;
                },
                () => {
                    return ModelOk();
                },
                (model: any) => {
                    if (!!validateIfFn && !validateIfFn(model)) return ModelOk();

                    if (!!correctFn) {
                        correctFn(model);
                        return ModelCorrected(model, validateFn);
                    }
                    const errorResponse = validateFn(model) as ErrorModelValidationResponse;
                    return ModelError(errorResponse.text);
                }
            )
        );
    }
}
