import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComponentGroup } from '../models/component-group.model';
import { ComponentGroupsState } from './component-groups.state';

export const getComponentGroupsState = createFeatureSelector<ComponentGroupsState>('componentGroups');

export const getComponentGroups = createSelector(getComponentGroupsState, (state: ComponentGroupsState) => {
    return !!state ? state.componentGroups : [];
});

export const isComponentGroupsLoading = createSelector(getComponentGroupsState,
    (state: ComponentGroupsState) => state && state.loading);

export const getComponentGroupsError = createSelector(getComponentGroupsState,
    (state: ComponentGroupsState) => state && state.error);

export const getSelectedComponentGroup = createSelector(getComponentGroupsState,
    (state: ComponentGroupsState) => state && state.selectedComponentGroup);

export const selectSelectedComponentGroupUuid = createSelector(getSelectedComponentGroup,
    (state: ComponentGroup) => state && state.uuid);

export const getIsContextManagingSelectedSiteIntegration = (uuid: string) => createSelector(
    getSelectedComponentGroup,
    ( selectedComponentGroup) => (selectedComponentGroup?.ownerCompanyUuid || selectedComponentGroup?.ownerPropertyUuid)?.toLowerCase() === uuid?.toLowerCase()
);
