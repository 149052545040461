export enum BookingEngineToken {
    Adults = 'adults',
    Children = 'children',
    Rooms = 'rooms',
    Beds = 'beds',
    StartDate = 'start-date',
    EndDate = 'end-date',
    Nights = 'nights',
    ProductBookingEngineCode = 'product-booking-engine-code',
    ItineraryBookingEngineCode = 'itinerary-booking-engine-code',
    RegionBookingEngineCode = 'region-booking-engine-code',
    PropertyBookingEngineCode = 'property-booking-engine-code',
    FallbackBookingEngineCode = 'fallback-booking-engine-code',
    PromoCode = 'promo-code',
    ShortCode = 'short-code',
    LanguageCode = 'language-code',
    TravelerAirport = 'traveler-airport-code',
    DestinationAirport = 'destination-airport-code',
    ChildAge = 'child-age',
    GroupCode = 'group-code',
    TravelAgentCode = 'travel-agent-code',
    RatePlanCode = 'rate-plan-code',
    CorporateCode = 'corporate-code',
    RoomCode = 'room-code'
}

