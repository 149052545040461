import {
    Component,
    forwardRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { EditorField, FieldValidationDefinition } from '@flipto/shared';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { Language } from 'account-hybrid/common/models/language.model';
import { EditorLanguageVariation } from '../../../models/editor-language-variation.model';
import { LanguageVariationsComponent } from '../../language-variations/language-variations.component';

@Component({
    selector: 'ft-multiple-input-list-editor',
    templateUrl: './multiple-input-list-editor.component.html',
    styleUrls: ['./multiple-input-list-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MultipleInputListEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: MultipleInputListEditorComponent
        }
    ]
})
export class MultipleInputListEditorComponent<I = any> extends ModalEditorBase implements OnInit {
    _validationDefinition: FieldValidationDefinition[];
    @Input() items: I[];
    @Input() fields: EditorField[];
    @Input() placeholder = '';
    @Input() bindLabel: string = 'label';
    @Input() bindVariations: string = 'languageData';
    @Input() bindVariationValue: string;
    @Input() bindVariationLabel: string;
    @Input() addBtnText: string = '+ Add';
    @Input() emptyMsgText: string;
    @Input() languageVariationsView = false;
    @Input() languages: Language[] = [];
    @Input()
    set validationDefinition(fieldsDefinition: FieldValidationDefinition[]) {
        this._validationDefinition = fieldsDefinition
            .map(definition => {
                if (definition.type === 'url') {
                    definition.patternValidationText = 'Value is not valid url.';
                    definition.pattern = 'https?://.+';
                }
                return definition;
            }) || [];
    };
    get validationDefinition(): FieldValidationDefinition[] {
        return this._validationDefinition;
    }

    activeVariationsList: EditorLanguageVariation[][] = [];

    @ViewChildren(LanguageVariationsComponent) languageVariationsRefs: QueryList<LanguageVariationsComponent>
    @ViewChildren('input', { read: NgModel }) inputs: QueryList<NgModel>;

    constructor() {
        super();
    }

    ngOnInit() {
        this.updateInitialValue();
    }

    addItem(item: I) {
        if (item) {
            (this.value || []).push(item);
        }
    }

    removeItem(itemIndex: number) {
        this.value = this.value.filter((val, index) => index !== itemIndex);
    }

    onModelChange(value: any, n?: number, i?: number, fieldName?: string) {
        if (this.isNgModelExist && this.isArrayValue()) {
            this.value[n][this.bindVariations][i][fieldName] = value;
        } else {
            this.value = value;
        }
    }

    isInvalid(): boolean {
        return this.inputs.some(input => input.invalid) || false;
    }

    isArrayValue() {
        return Array.isArray(this.value);
    }

    trackByFn(item, index) {
        return index;
    }

    isValueRemovable(i: number, n?: number) {
        return this.value[n]?.[this.bindVariations]?.find((_, index) => i === index)?.languageCode !== 'en';
    }

    removeValue(i: number, fieldName: string, n?: number) {
        if (Array.isArray(this.value[n][this.bindVariations])) {
            this.value[n][this.bindVariations][i][fieldName] = '';

            this.languageVariationsRefs.get(n).removeVariation(this.value[n][this.bindVariations][i]?.languageCode);
        }
    }

    onActiveVariationChanged(variations: EditorLanguageVariation[], n?: number) {
        this.activeVariationsList[n] = variations;
    }

    isVariationActive(languageCode: string, n?: number) {
        return this.activeVariationsList[n]?.find(variation => variation.languageCode === languageCode)?.isVariationActive;
    }

    getActiveVariationsCount(n: number): number {
        return this.activeVariationsList[n]?.filter(variation => variation.isVariationActive)?.length;
    }

    get availableItems() {
        return this.items.filter(i => !(this.value || []).find(val => val[this.bindLabel]?.toLowerCase() === i[this.bindLabel]?.toLowerCase()));
    }
}


