import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExperimentTimelineComponent } from './experiment-timeline/experiment-timeline.component';
import { SharedModule } from 'shared';
import { DiscoveryTrafficStatsComponent } from './discovery-traffic-stats/discovery-traffic-stats.component';
import { DiscoveryPlannerStatsComponent } from './discovery-planner-stats/discovery-planner-stats.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NgbTooltipModule, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        ExperimentTimelineComponent,
        DiscoveryTrafficStatsComponent,
        DiscoveryPlannerStatsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        MatIconModule,
        MatDialogModule,
        NgbTooltipModule,
    ],
    exports: [
        ExperimentTimelineComponent,
        DiscoveryTrafficStatsComponent,
        DiscoveryPlannerStatsComponent
    ],
})
export class CommonDiscoveryModule {
    constructor(config: NgbTooltipConfig) {
        config.triggers = 'click';
        config.autoClose = 'outside';
        config.placement = ['auto'];
    }
}
