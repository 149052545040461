import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterUpgradeModule } from "@uirouter/angular-hybrid";
import { AccountCommonModule } from "../../common/common.module";
import { SignInComponent } from "./pages/sign-in/sign-in.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { StoreModule } from "@ngrx/store";
import { authenticationReducer } from "./store/authentication.reducer";
import { SharedModule } from 'shared';
import { EffectsModule } from "@ngrx/effects";
import { AuthenticationWrapperComponent } from './components/authentication-wrapper/authentication-wrapper.component';
import { FormsModule } from "@angular/forms";
import { AuthenticationEffects } from "./store/authentication.effects";
import { MatIconModule } from "@angular/material/icon";
import { Transition } from "@uirouter/core";
import { CreatePasswordComponent } from './pages/create-password/create-password.component';
import { PasswordStrengthModule } from "../../common/modules/password-strength/password-strength.module";
import { RequestLinkComponent } from './components/request-link/request-link.component';
import { ChangePasswordErrorsComponent } from './components/change-password-errors/change-password-errors.component';
import { ProfileComponent } from './pages/profile/profile.component';

@NgModule({
    declarations: [
        SignInComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        AuthenticationWrapperComponent,
        CreatePasswordComponent,
        RequestLinkComponent,
        ChangePasswordErrorsComponent,
        ProfileComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        AccountCommonModule,
        MatIconModule,
        UIRouterUpgradeModule.forChild({
            states: [
                {
                    name: 'sign-in',
                    url: '/sign-in?redirectTo',
                    component: SignInComponent,
                    resolve: [
                        {
                            token: 'redirectTo',
                            deps: [Transition],
                            resolveFn: (trans: Transition) =>
                                trans.params().redirectTo
                        }
                    ]
                },
                {
                    name: 'recover-password',
                    url: '/recover-password',
                    component: ForgotPasswordComponent
                },
                {
                    name: 'change-password',
                    url: '/change-password?token&id',
                    component: ChangePasswordComponent,
                    resolve: [
                        {
                            token: 'userUuid',
                            deps: [Transition],
                            resolveFn: (trans: Transition) =>
                                trans.params().id
                        },
                        {
                            token: 'token',
                            deps: [Transition],
                            resolveFn: (trans: Transition) =>
                                trans.params().token
                        }
                    ],
                    data: {
                        requiresParams: ['id', 'token']
                    }
                },
                {
                    name: 'create-password',
                    url: '/create-password?token&id&c',
                    component: CreatePasswordComponent,
                    resolve: [
                        {
                            token: 'userUuid',
                            deps: [Transition],
                            resolveFn: (trans: Transition) =>
                                trans.params().id
                        },
                        {
                            token: 'token',
                            deps: [Transition],
                            resolveFn: (trans: Transition) =>
                                trans.params().token
                        },
                        {
                            token: 'companyCode',
                            deps: [Transition],
                            resolveFn: (trans: Transition) =>
                                trans.params().c
                        }
                    ],
                    data: {
                        requiresParams: ['id', 'c', 'token']
                    }
                },
                {
                    name: 'profile',
                    url: '/profile?backUrl',
                    component: ProfileComponent,
                    data: {
                        requiresAuth: true
                    }
                }
            ]
        }),
        PasswordStrengthModule.forChild(),
        StoreModule.forFeature('authentication', authenticationReducer),
        EffectsModule.forFeature([AuthenticationEffects]),
    ]
})
export class AuthenticationModule {
    constructor() {
    }
}
