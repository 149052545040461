export enum LanguageName {
    "ar" = "Arabic",
    "ca" = "Catalan",
    "cs" = "Czech",
    "de" = "German",
    "en" = "English",
    "es" = "Spanish (Traditional)",
    "es-mx" = "Spanish (Latin)",
    "fr" = "French",
    "he" = "Hebrew",
    "it" = "Italian",
    "pt" = "Portuguese",
    "ru" = "Russian",
    "tr" = "Turkish"
}


