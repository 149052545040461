import { GA_FT_PARAM } from "../../constants/ga-ft-param.const";
import {DEBUG_PARAMETER, FT_DEBUG_PARAMETER, FT_PREVIEW_PARAMETER} from "../constants";
import { GoogleAnalytics4Util } from "./google-analytics-4.util";
import { CookieService } from '@flipto/shared-discovery/services/cookie.service';

export class UrlUtil {
    public static rootDomain(): string {
        const parts = document.location.hostname.split('.');
        const uniqueId = '_ft-domain-detector-' + (new Date()).getTime();

        // Start checking domains from the right hand side with at least two sections i.e. x.tld
        for (var i = 2; i < parts.length; i++) {
            // Attempt to place a cookie on the constructed domain
            const domain = parts.slice(-i).join('.');
            document.cookie = uniqueId + '=1;domain=' + domain + ';';

            // Check if cookie was placed successfully
            if (document.cookie.indexOf(uniqueId + '=1') !== -1) {
                // Remove the last (and only successful) cookie
                document.cookie = uniqueId + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + domain + ';';
                return domain;
            }
        }

        // No lower root domain available
        return document.location.hostname;
    }

    public static parseUrl(url: string) {
        if (!url) return {};
        const link = document.createElement("a");
        link.href = url;
        return {
            absolute: link.href,
            hash: link.hash,
            hashParams: new URLSearchParams(link.hash.split('?')[1] || ''),
            host: link.host,
            hostname: link.hostname,
            origin: link.origin,
            original: url,
            originalOriginPath: url.split(/[\?#]/)[0],
            pathname: link.pathname,
            query: link.search,
            queryParams: new URLSearchParams(link.search),
        }
    }

    public static addToPath(url: string, path: string): string {
        if (url) {
            const queryChunks = url.split('?');
            const hashChunks = queryChunks[0].split('#');
            hashChunks[0] += path;
            queryChunks[0] = hashChunks.join('#');
            return queryChunks.join('?');
        }
    }

    public static hasKey(url: string, key: string, ignoreCase = true): boolean {
        return !!key && (this.getValue(url, key, ignoreCase) != null);
    }

    public static getValue(url: string, key: string, ignoreCase = true): string {
        if (!key || !url) {
            return null;
        }
        const { query, hash, queryParams, hashParams } = this.parseUrl(url);
        // NOTE: can't use UrlSearchParams for case-insensitive search because typescript says it's not iterable for some reason
        const keyRegex = new RegExp(`[?&#]?${key}=([^&#]*)`, 'i');

        // Coalesce eats empty-string, so use null comparison instead
        const queryValue = ignoreCase ? query.match(keyRegex)?.[1] : queryParams.get(key);
        if (queryValue != null) return queryValue;
        const hashValue = ignoreCase ? hash.match(keyRegex)?.[1] : hashParams.get(key);
        if (hashValue != null) return hashValue;
        return null;
    }

    public static addToQueryString(url: string, key: string, value: string): string {
        if (!url || !key || value == null) {
            return url;
        }

        // Parse url so we can get urlsearchparams
        const { query, hash, originalOriginPath } = this.parseUrl(url);

        // If it's already in the fragment, do a replace
        const regex = new RegExp(`${key}=[^&#]*`);

        // Replace if it exists, otherwise append
        const newQuery = regex.test(query)
            ? query.replace(regex, `${key}=${encodeURIComponent(value)}`)
            : `${query}${query.includes('?') ? '&' : '?'}${key}=${encodeURIComponent(value)}`;

        // Build new url
        return originalOriginPath + newQuery + hash;
    }

    public static addToFragment(url: string, key: string, value: string): string {
        if (!url || !key || value == null) {
            return url;
        }

        // Parse url so we can get urlsearchparams
        const { hash, query, originalOriginPath } = this.parseUrl(url);

        // If it's already in the fragment, do a replace
        const regex = new RegExp(`${key}=[^&#]*`);

        // Replace if it exists, otherwise append
        const newHash = regex.test(hash)
            ? hash.replace(regex, `${key}=${encodeURIComponent(value)}`)
            : `${hash || '#'}${hash.includes('?') ? '&' : '?'}${key}=${encodeURIComponent(value)}`;

        // Build new url
        return originalOriginPath + query + newHash;
    }

    public static removeQueryParams(url: string): string {
        if (!url) {
            return url;
        }
        const { origin, pathname, hash } = UrlUtil.parseUrl(url);
        return origin + pathname + hash;
    }

    public static removePathParams(url: string): string {
        if (!url) {
            return url;
        }
        const { origin, query, hash } = UrlUtil.parseUrl(url);
        return origin + query + hash;
    }


    public static addTrackingParameters(url: string, isAppendingReferralParams: boolean = false) {
        try {
            // Add _ga-ft param with validation in GTM
            const w = window as any;
            let newUrl = url;

            // Get current and destination domains for hostname checks
            const currentRoot = UrlUtil.rootDomain();
            const { hostname } = UrlUtil.parseUrl(newUrl);

            // subdomains and external links get checked for decoration
            if (hostname !== location.hostname) {
                const hashIndex = newUrl.indexOf('#');
                const useHash = hashIndex >= 0 && hashIndex < newUrl.indexOf(`${GA_FT_PARAM}=`);

                // Add _ga-ft to external links and subdomains
                if ('function' === typeof w.fliptoDecorate) {
                    newUrl = w.fliptoDecorate(newUrl, true);
                }

                // Add external tracking params for non subdomains only
                if (!hostname.endsWith(currentRoot)) {

                    try {
                        // Google analtyics decoration (can detect cross-domain by checking plugins)
                        const ga = w[w.GoogleAnalyticsObject];
                        if ('function' === typeof ga?.getAll) {
                            // Get first tracker with linker plugin
                            const linkingTracker = ga.getAll().find(tracker => tracker.plugins_ && Object.keys(tracker.plugins_).find(key => tracker.plugins_[key]?.[':linker']));
                            if (linkingTracker) {
                                const a = document.createElement('a');
                                a.href = newUrl;
                                ga(linkingTracker.get('name') + '.linker:decorate', a, useHash);
                                newUrl = a.href;
                            }
                        }

                        // Google Analytics 4 decoration
                        const ga4Decorators = w.google_tag_data?.gl?.decorators;
                        if (Array.isArray(ga4Decorators) && ga4Decorators.length) {
                            // Derived from https://business.safety.google/adscookies/
                            const cookieObj = CookieService.find(/^_ga/, /^_gid/, /^_gcl/, /^_fplc/);
                            const gl = GoogleAnalytics4Util.generateGl(cookieObj);
                            for (const decorator of ga4Decorators) {
                                // If decorator applies
                                if (Array.isArray(decorator.domains) && decorator.domains.some((aDomain: string | RegExp) => new RegExp(aDomain).test(newUrl))) {
                                    // Placement is configurable
                                    newUrl = decorator.fragment
                                        ? UrlUtil.addToFragment(newUrl, "_gl", gl)
                                        : UrlUtil.addToQueryString(newUrl, "_gl", gl);
                                    break;
                                }
                            }
                        }

                        // Adobe analytics decoration (cross-domain must be opt-in via ftParams)
                        if (w.ftParams?.enableAdobeCrossDomain && 'function' == typeof w.Visitor?.getInstance) {
                            const adobeId = document.cookie.split('; ').find(aCookie => aCookie.startsWith("AMCVS_"))?.slice(6, -2);
                            if (adobeId) {
                                const visitor = w.Visitor.getInstance(decodeURIComponent(adobeId));
                                if (visitor && 'function' === typeof visitor.appendVisitorIDsTo) {
                                    newUrl = visitor.appendVisitorIDsTo(newUrl);
                                }
                            }
                        }
                    } catch (_) {
                    }
                }

                // Optionally append UTM parameters
                if (isAppendingReferralParams) {
                    const append = useHash ? UrlUtil.addToFragment : UrlUtil.addToQueryString;
                    newUrl = append(newUrl, 'utm_source', currentRoot);
                    newUrl = append(newUrl, 'utm_medium', 'referral');
                    newUrl = append(newUrl, 'utm_campaign', 'ft-discovery');
                }
            }
            return newUrl;
        } catch (_) {
        }
        return url;
    }

    public static isDebug(url: string = window.location.href) : boolean {
        return UrlUtil.hasKey(url, DEBUG_PARAMETER) || UrlUtil.hasKey(url, FT_DEBUG_PARAMETER);
    }

    public static isPreview(url: string = window.location.href) : boolean {
        return UrlUtil.hasKey(url, FT_PREVIEW_PARAMETER);
    }
}
