<ft-modal-editor-wrapper>
    <ng-template #triggerTmpl>
        <ng-content></ng-content>
    </ng-template>
    <div class="ft2-edit-dialog_modal pt-0"
         [style.min-height]="'380px'"
         [style.max-width]="'670px'">
        <mat-tab-group class="ft2-image-library-tabs mat-tab-group-nav"
                       [disablePagination]="true"
                       #tabs
                       mat-align-tabs="center"
                       animationDuration="0ms"
                       [(selectedIndex)]="activeTabIndex">
            <mat-tab class="p-0">
                <div class="my-4 text-center">
                    <button class="ft2-btn-dark -outline -dark" (click)="activeTabIndex = 1">
                        <span class="medium-text">Use a different image</span>
                    </button>
                </div>
                <ft-image-preview height="200px"
                                  width="100%"
                                  [frame]="false"
                                  [src]="value">
                </ft-image-preview>
                <p class="mt-3 text-center">
                    {{getImageName(value)}}
                </p>
            </mat-tab>
            <mat-tab label="Library">
                <div class="mt-4">
                    <label class="ft2-label h-center">
                        <ft-loading [loading$]="loading$">
                            Images not yet uploaded
                        </ft-loading>
                        <ng-container *ngIf="(images$ | async)?.length">
                            Choose from images
                        </ng-container>
                    </label>
                    <div class="h-center">
                        <div class="ft2-image-library-list">
                            <ft-image-preview *ngFor="let image of images$ | async"
                                              (click)="changeModelImage(image)"
                                              height="100px"
                                              width="100px"
                                              [frame]="true"
                                              [src]="image.url">
                            </ft-image-preview>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Upload">
                <div class="mt-4">
                    <ft-dropzone-uploader
                            [loading]="loading$ | async"
                            [label]="'Drop an image here (or click to upload it)'"
                            [multiple]="false"
                            [accept]="accept"
                            (selected)="onImageFileUpload($event)">
                    </ft-dropzone-uploader>
                </div>

            </mat-tab>
            <mat-tab label="Image url">
                <div class="my-9">
                    <label class="ft2-label h-center" for="image-library-external-url">
                        Enter url of image
                    </label>
                    <div class="ft2-input-group">
                        <input [(ngModel)]="externalImageUrl" id="image-library-external-url" class="ft2-input -large" type="text">
                        <div class="ft2-input-group_append">
                            <button class="ft2-btn-primary"
                                    [disabled]="!externalImageUrl"
                                    [ftLoading]="loading$"
                                    (click)="onImageFromUrlUpload(this.externalImageUrl)">
                                Go
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <button *ngIf="removable" class="ft2-edit-dialog_footer-control ft2-btn-light -large -outline mr-3" (click)="onRemove()">
        Remove
    </button>
</ft-modal-editor-wrapper>
