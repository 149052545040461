/**
 * Created by ostapenko.r on 1/29/2015.
 */


angular.module('flipto.accountv1.SharedFeatures')
    .controller('flipto.account.sections.account.ReportsController', [
        'companyName', 'properties', '$filter', '_', 'Reports', 'dateSerializer', 'snapshot', 'urlFormatter', 'IdentityService', 'SpacesService',
        function (companyName, properties, $filter, _, Reports, dateSerializer, snapshot, urlFormatter, identity, spacesService) {

            var translate = $filter('translate');
            var vm = this;
            vm.identity = identity;
            angular.extend(vm, {
                company: !!companyName ? { Name: companyName } : null,
                properties: properties,
                snapshot: { start: snapshot.get().start, end: snapshot.get().end }
            });

            vm.onFilterChanged = function (group, report) {
                report.url = vm.getContextBasedReportUrl(group, report, vm.selectedContext);
            };

            vm.dateRangeChanged = function (dateRange) {
                vm.snapshot.start = dateRange.fromDate;
                vm.snapshot.end = dateRange.toDate;
                vm.syncReportsUrls();
            };

            vm.changeContext = function (context) {
                vm.selectedContext = context;
                vm.syncReportsUrls();
            };

            vm.setCompanyContext = function (context) {
                vm.contextType = 'company';
                vm.changeContext(context);
            }

            vm.setPropertyContext = function (context) {
                vm.contextType = 'property';
                vm.changeContext(context);
            }

            vm.getContextBasedReportUrl = function (group, report, context) {
                var params = {
                    id: report.id,
                    startDate: vm.snapshot.start && dateSerializer(vm.snapshot.start),
                    endDate: vm.snapshot.end && dateSerializer(vm.snapshot.end),
                    propertyUUID: vm.contextType === 'property' ? vm.selectedContext.UUID : null,
                    isRestricted: !group.filtered ? null : !!report.filters && report.filters[1].active
                };
                return urlFormatter.format(vm.contextType === 'property' ? "/api/" + spacesService.current.id + "/properties/:propertyUUID/reports/:id" : ("/api/" + spacesService.current.id + "/account/reports/:id"), params, true);
            };

            function createReport(report) {
                return {
                    id: report.id,
                    name: translate("flipto.account.reports." + report.type),
                    description: translate("flipto.account.reports." + report.type + "Description")
                };
            }

            function createFilteredReport(report) {
                return angular.extend(createReport(report), {
                    filters: [
                        { name: "Standard list", active: true },
                        {
                            name: "Restricted list",
                            text: "The restricted list contains personally identifiable information for users who are protected under data protection regulations. IT IS UNLAWFUL TO USE THIS INFORMATION FOR MARKETING PURPOSES.",
                            active: false
                        }
                    ]
                });
            }

            vm.syncReportsUrls = function () {
                vm.reports.forEach(function (group) {
                    group.reports.forEach(function (report) {
                        report.url = vm.getContextBasedReportUrl(group, report, vm.selectedContext);
                    });
                });
            };

            vm.reports = [
                {
                    title: "Operations",
                    filtered: false,
                    reports: [
                        { id: 1, type: "Advocates" },
                        { id: 2, type: "Certificates" },
                        { id: 11, type: "Feedback" }
                    ]
                },
                {
                    title: "Audience Retargeting",
                    filtered: true,
                    reports: [
                        { id: 3, type: "SignupsStorytellerFriends" },
                        { id: 4, type: "SignupsStorytellerVisitors" },
                        { id: 5, type: "SignupsStayAdvocacy" },
                        { id: 6, type: "SignupsInterested" },
                        { id: 7, type: "SignupsExpired" },
                        { id: 14, type: "DiscoveryPlanners" }
                    ]
                },
                {
                    title: "Marketing Content",
                    filtered: false,
                    reports: [
                        { id: 8, type: "Stories" }
                    ]
                },
                {
                    title: "Performance",
                    filtered: false,
                    reports: [
                        { id: 9, type: "Sources" },
                        { id: 10, type: "Statistics" },
                        { id: 12, type: "OccupancyByStayDate" },
                        { id: 13, type: "OccupancyByBookingDate" }
                    ]
                }
            ];

            if (identity.isFliptoStaff()) {
                vm.reports.push({
                    title: "FlipTo Admin",
                    filtered: true,
                    reports: [
                        { id: 15, type: "DiscoveryPlannersTroubleshooting" }
                    ]
                });
            }

            vm.reports = vm.reports.map(function (group) {
                group.reports = group.reports.map(function (report) {
                    return group.filtered ? createFilteredReport(report) : createReport(report);
                });
                return group;
            });
            
            if (!vm.company) {
                vm.setPropertyContext(vm.properties[0]);
            } else if (vm.properties.length === 0 || vm.properties.length > 1) {
                vm.setCompanyContext(vm.company);
            } else {
                vm.setPropertyContext(vm.properties[0]);
            }
        }
    ]);
