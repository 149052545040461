<ft-modal-wrapper>
    <ft-touchpoint-header [name]="'Booking bar'" [canActivate]="!!(selectedBookingBarComponent$ | async)?.selector"
                          (activeToggled)="commit({isActive: $event})"
                          [isActive]="(selectedBookingBarComponent$ | async).isActive">
    </ft-touchpoint-header>
    <mat-tab-group class="mat-tab-group-nav" mat-align-tabs="center" animationDuration="0ms"
                   [(selectedIndex)]="selectedIndex">
        <mat-tab label="styling">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form (commit)="commit($event)"
                                      [useFlattenStrategy]="true"
                                      [loading$]="isLoading$"
                                      [commitSuccess$]="commitSuccess$">
                        <form ngForm>
                            <ng-container *ngIf="selectedBookingBarComponent$ | async | clone as selectedComponent; else noData">
                                <section class="ft2-block -small">
                                    <div class="ft2-container">
                                        <ft-code-editor [language]="'css'" [title]="'CSS'" name="css"
                                                        [(ngModel)]="selectedComponent.css">
                                            <ft-edit-panel label="CSS" [loading]="isLoading$ | async"
                                                           [error]="error$ | async">
                                                <div class="col-8" [innerHTML]="selectedComponent.css | ftPlaceholder"></div>
                                            </ft-edit-panel>
                                        </ft-code-editor>
                                    </div>
                                </section>
                            </ng-container>
                        </form>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
        <mat-tab label="integration">
            <ng-template matTabContent>
                <ft-permissions [ftPermissions]="'FliptoAdministration'" [hide]="false">
                    <ft-snapshot-form (commit)="commit($event)"
                                      [useFlattenStrategy]="true"
                                      [loading$]="isLoading$"
                                      [commitSuccess$]="commitSuccess$">
                        <form ngForm>
                            <ng-container *ngIf="selectedBookingBarComponent$ | async | clone as selectedComponent; else noData">
                                <ng-container *ngIf="messaging">
                                    <ng-container *ngIf="plannerFlowSettings$ | async | clone as plannerFlowData">
                                        <section class="ft2-block -small">
                                            <div class="ft2-container">
                                                <ng-container *ngIf="languages$ | async as languages">
                                                    <ft-language-preview-panel [languages]="languages"
                                                                               *ngIf="languages.length > 1"
                                                                               [selectedLanguage]="selectedPreviewLanguage"
                                                                               (previewLanguageChange)="onPreviewLanguageChange($event)"
                                                                               [simplified]="true">
                                                    </ft-language-preview-panel>
                                                </ng-container>
                                                <ft-custom-editor title="Page location">
                                                    <div content>
                                                        <div class="ft2-form-group">
                                                            <label>Inserting strategy</label>
                                                            <ng-select [items]="domInjectionStrategyOptions"
                                                                       class="ft2-select -large mb-3"
                                                                       [name]="'domInjectionStrategy'"
                                                                       [bindLabel]="'label'"
                                                                       [bindValue]="'value'" [searchable]="false"
                                                                       [clearable]="false"
                                                                       [(ngModel)]="selectedComponent.domInjectionStrategy">
                                                            </ng-select>
                                                        </div>
                                                        <div class="ft2-form-group">
                                                            <label>Target element(s)</label>
                                                            <input class="ft2-input -large" type="text"
                                                                   placeholder="#elementID, .elementClassName, div[attribute='value']"
                                                                   required [name]="'selector'"
                                                                   [(ngModel)]="selectedComponent.selector">
                                                        </div>
                                                        <div class="ft2-form-group">
                                                            <label>Exclude element(s)</label>
                                                            <input class="ft2-input -large" type="text"
                                                                   placeholder="#elementID, .elementClassName, div[attribute='value']"
                                                                   [name]="'antiSelector'"
                                                                   [(ngModel)]="selectedComponent.antiSelector">
                                                        </div>
                                                    </div>
                                                    <ft-edit-panel label="Page location" [loading]="isLoading$ | async">
                                                        <div>
                                                            <div>
                                                                <span *ngIf="selectedComponent.domInjectionStrategy !== null">
                                                                    {{getLabel(selectedComponent.domInjectionStrategy)}}
                                                                </span>
                                                                <span *ngIf="selectedComponent.selector">
                                                                    {{selectedComponent.selector}}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span *ngIf="selectedComponent.antiSelector">
                                                                    Exclude {{selectedComponent.antiSelector}}
                                                                </span>
                                                                <span
                                                                    *ngIf="selectedComponent.domInjectionStrategy === null && !selectedComponent.selector">
                                                                    Please provide value
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ft-edit-panel>
                                                </ft-custom-editor>
                                                <ft-custom-editor title="Destination field">
                                                    <div content>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isProductVisible">Visibility</label>
                                                                <mat-slide-toggle name="isProductVisible"
                                                                                  [(ngModel)]="selectedComponent.isProductVisible">
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isProductRequired">Required</label>
                                                                <mat-slide-toggle name="isProductRequired"
                                                                                  [(ngModel)]="selectedComponent.isProductRequired">
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                        <div class="ft2-form-group">
                                                            <label>Label</label>
                                                        </div>
                                                        <div class="ft2-form-group"
                                                             *ngFor="let message of messaging; let i = index;">
                                                            <div class="ft2-input-container">
                                                                <div class="d-flex f-nowrap v-center">
                                                                    <input class="ft2-input -large" type="text"
                                                                           [name]="'messaging[' + i + '].selectProductText'"
                                                                           [(ngModel)]="message.selectProductText">
                                                                    <span class="ft2-input-language"
                                                                          *ngIf="messaging?.length > 1">
                                                                    {{ message?.languageName }}
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ft-edit-panel label="Destination field"
                                                                   [loading]="isLoading$ | async"
                                                                   [error]="error$ | async">
                                                        <div [class.muted-text]="!selectedComponent.isProductVisible">
                                                            <span>
                                                                The field is {{selectedComponent.isProductVisible ? 'on' : 'off'}}
                                                            </span>
                                                            <span *ngIf="selectedComponent.isProductVisible">
                                                                <span *ngIf="selectedComponent.isProductRequired">
                                                                    and required
                                                                </span>
                                                            </span>
                                                            <div class="mt-2" *ngIf="selectedComponent.isProductVisible">
                                                                <div
                                                                    *ngIf="(messaging | languagePreview: 'selectProductText' : selectedPreviewLanguage?.languageCode) as productText">
                                                                    Label: {{ productText }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ft-edit-panel>
                                                </ft-custom-editor>
                                                <ft-custom-editor title="Date field">
                                                    <div content>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isCheckInVisible">Visibility</label>
                                                                <mat-slide-toggle name="isCheckInVisible"
                                                                                  [(ngModel)]="selectedComponent.isCheckInVisible">
                                                                </mat-slide-toggle>
                                                                <input type="hidden" name="isCheckOutVisible"
                                                                       [(ngModel)]="selectedComponent.isCheckInVisible">
                                                            </div>
                                                        </div>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isCheckInRequired">Required</label>
                                                                <mat-slide-toggle name="isCheckInRequired"
                                                                                  [(ngModel)]="selectedComponent.isCheckInRequired">
                                                                </mat-slide-toggle>
                                                                <input type="hidden" name="isCheckOutRequired"
                                                                       [(ngModel)]="selectedComponent.isCheckInRequired">
                                                            </div>
                                                        </div>
                                                        <ng-container
                                                            *ngFor="let message of messaging; let i = index; let first = first;">
                                                            <div class="ft2-form-group">
                                                                <label for="messaging[0].selectCheckInText"
                                                                       *ngIf="first">
                                                                    Label for start date
                                                                </label>
                                                                <div class="ft2-input-container">
                                                                    <div class="d-flex f-nowrap v-center">
                                                                        <input class="ft2-input -large" type="text"
                                                                               [name]="'messaging[' + i + '].selectCheckInText'"
                                                                               [(ngModel)]="message.selectCheckInText">
                                                                        <span class="ft2-input-language"
                                                                              *ngIf="messaging?.length > 1">
                                                                    {{ message?.languageName }}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngFor="let message of messaging; let i = index; let first = first;">
                                                            <div class="ft2-form-group">
                                                                <label for="messaging[0].selectCheckOutText"
                                                                       *ngIf="first">
                                                                    Label for end date
                                                                </label>
                                                                <div class="ft2-input-container">
                                                                    <div class="d-flex f-nowrap v-center">
                                                                        <input class="ft2-input -large" type="text"
                                                                               [name]="'messaging[' + i + '].selectCheckOutText'"
                                                                               [(ngModel)]="message.selectCheckOutText">
                                                                        <span class="ft2-input-language"
                                                                              *ngIf="messaging?.length > 1">
                                                                    {{ message?.languageName }}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <ft-edit-panel label="Date field" [loading]="isLoading$ | async"
                                                                   [error]="error$ | async">
                                                        <div [class.muted-text]="!selectedComponent.isCheckInVisible">
                                                            <span>
                                                                This field is {{selectedComponent.isCheckInVisible ? 'on' : 'off'}}
                                                            </span>
                                                            <span *ngIf="selectedComponent.isCheckInVisible">
                                                                <span *ngIf="selectedComponent.isCheckInRequired">
                                                                    and required
                                                                </span>
                                                            </span>
                                                            <div class="mt-2" *ngIf="selectedComponent.isCheckInVisible">
                                                                <div
                                                                    *ngIf="plannerFlowData.defaultStayLength === 0 || plannerFlowData.defaultStayLength > 0">
                                                                    Default
                                                                    nights: {{plannerFlowData.defaultStayLength}}
                                                                    (<span
                                                                    *ngIf="plannerFlowData.minNights === 0 || plannerFlowData.minNights > 0">min {{plannerFlowData.minNights}}</span>,
                                                                    <span
                                                                        *ngIf="plannerFlowData.maxNights === 0 || plannerFlowData.maxNights > 0">max {{plannerFlowData.maxNights}}</span>)
                                                                </div>
                                                                <div
                                                                    *ngIf="(messaging | languagePreview: 'selectCheckInText' : selectedPreviewLanguage?.languageCode) as selectCheckInText">
                                                                    Label for start
                                                                    date: {{ selectCheckInText }}
                                                                </div>
                                                                <div
                                                                    *ngIf="(messaging | languagePreview: 'selectCheckOutText' : selectedPreviewLanguage?.languageCode) as selectCheckOutText">
                                                                    Label for end date: {{ selectCheckOutText }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ft-edit-panel>
                                                </ft-custom-editor>
                                                <ft-custom-editor title="Adults field">
                                                    <div content>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isAdultsVisible">Visibility</label>
                                                                <mat-slide-toggle name="isAdultsVisible"
                                                                                  [(ngModel)]="selectedComponent.isAdultsVisible">
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isAdultsRequired">Required</label>
                                                                <mat-slide-toggle name="isAdultsRequired"
                                                                                  [(ngModel)]="selectedComponent.isAdultsRequired">
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                        <ng-container
                                                            *ngFor="let message of messaging; let i = index; let first = first;">
                                                            <div class="ft2-form-group">
                                                                <label for="messaging[0].selectAdultsText"
                                                                       *ngIf="first">
                                                                    Label
                                                                </label>
                                                                <div class="ft2-input-container">
                                                                    <div class="d-flex f-nowrap v-center">
                                                                        <input class="ft2-input -large" type="text"
                                                                               [name]="'messaging[' + i + '].selectAdultsText'"
                                                                               [(ngModel)]="message.selectAdultsText">
                                                                        <span class="ft2-input-language"
                                                                              *ngIf="messaging?.length > 1">
                                                                    {{ message?.languageName }}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <ft-edit-panel label="Adults field" [loading]="isLoading$ | async"
                                                                   [error]="error$ | async">
                                                        <div [class.muted-text]="!selectedComponent.isAdultsVisible">
                                                            <span>
                                                                This field is {{selectedComponent.isAdultsVisible ? 'on' : 'off'}}
                                                            </span>
                                                            <span *ngIf="selectedComponent.isAdultsVisible">
                                                                <span *ngIf="selectedComponent.isAdultsRequired">
                                                                    and required
                                                                </span>
                                                            </span>
                                                            <div class="mt-2" *ngIf="selectedComponent.isAdultsVisible">
                                                                <div
                                                                    *ngIf="plannerFlowData.defaultAdults === 0 || plannerFlowData.defaultAdults > 0">
                                                                    Default adults: {{plannerFlowData.defaultAdults}}
                                                                    (<span
                                                                    *ngIf="plannerFlowData.minAdults === 0 || plannerFlowData.minAdults > 0">min {{plannerFlowData.minAdults}}</span>,
                                                                    <span
                                                                        *ngIf="plannerFlowData.maxAdults === 0 || plannerFlowData.maxAdults > 0">max {{plannerFlowData.maxAdults}}</span>)
                                                                </div>
                                                                <div *ngIf="(messaging | languagePreview: 'selectAdultsText' : selectedPreviewLanguage?.languageCode) as selectAdultsText">
                                                                    Label: {{ selectAdultsText }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ft-edit-panel>
                                                </ft-custom-editor>
                                                <ft-custom-editor title="Kids field">
                                                    <div content>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isChildrenVisible">Visibility</label>
                                                                <mat-slide-toggle name="isChildrenVisible"
                                                                                  [(ngModel)]="selectedComponent.isChildrenVisible">
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                        <div class="ft2-form-group">
                                                            <div class="ft2-input -large v-center h-between">
                                                                <label for="isChildrenRequired">Required</label>
                                                                <mat-slide-toggle name="isChildrenRequired"
                                                                                  [(ngModel)]="selectedComponent.isChildrenRequired">
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                        <ng-container
                                                            *ngFor="let message of messaging; let i = index; let first = first;">
                                                            <div class="ft2-form-group">
                                                                <label for="messaging[0].selectChildrenText"
                                                                       *ngIf="first">
                                                                    Label
                                                                </label>
                                                                <div class="ft2-input-container">
                                                                    <div class="d-flex f-nowrap v-center">
                                                                        <input class="ft2-input -large" type="text"
                                                                               [name]="'messaging[' + i + '].selectChildrenText'"
                                                                               [(ngModel)]="message.selectChildrenText">
                                                                        <span class="ft2-input-language"
                                                                              *ngIf="messaging?.length > 1">
                                                                    {{ message?.languageName }}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <ft-edit-panel label="Kids field" [loading]="isLoading$ | async"
                                                                   [error]="error$ | async">
                                                        <div [class.muted-text]="!selectedComponent.isChildrenVisible">
                                                            <span>
                                                                This field is {{selectedComponent.isChildrenVisible ? 'on' : 'off'}}
                                                            </span>
                                                            <span *ngIf="selectedComponent.isChildrenVisible">
                                                                <span *ngIf="selectedComponent.isChildrenRequired">
                                                                    and required
                                                                </span>
                                                            </span>
                                                            <div class="mt-2" *ngIf="selectedComponent.isChildrenVisible">
                                                                <div
                                                                    *ngIf="plannerFlowData.defaultChildren === 0 || plannerFlowData.defaultChildren > 0">
                                                                    Default kids: {{plannerFlowData.defaultChildren}}
                                                                    (<span
                                                                    *ngIf="plannerFlowData.minChildren === 0 || plannerFlowData.minChildren > 0">min {{plannerFlowData.minChildren}}</span>,
                                                                    <span
                                                                        *ngIf="plannerFlowData.maxChildren === 0 || plannerFlowData.maxChildren > 0">max {{plannerFlowData.maxChildren}}</span>)
                                                                </div>
                                                                <div *ngIf="(messaging | languagePreview: 'selectChildrenText' : selectedPreviewLanguage?.languageCode) as selectChildrenText">
                                                                    Label: {{ selectChildrenText }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ft-edit-panel>
                                                </ft-custom-editor>
                                                <ft-custom-editor title="Submit button label">
                                                    <div content>
                                                        <ng-container
                                                            *ngFor="let message of messaging; let i = index; let first = first;">
                                                            <div class="ft2-form-group">
                                                                <div class="ft2-input-container">
                                                                    <div class="d-flex f-nowrap v-center">
                                                                        <input class="ft2-input -large" type="text"
                                                                               [name]="'messaging[' + i + '].submitButtonText'"
                                                                               [(ngModel)]="message.submitButtonText">
                                                                        <span class="ft2-input-language"
                                                                              *ngIf="messaging?.length > 1">
                                                                    {{ message?.languageName }}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <ft-edit-panel label="Submit button label"
                                                                   [loading]="isLoading$ | async"
                                                                   [error]="error$ | async">
                                                        <div *ngIf="(messaging | languagePreview: 'submitButtonText' : selectedPreviewLanguage?.languageCode) as submitButtonText">
                                                            {{ submitButtonText }}
                                                        </div>
                                                    </ft-edit-panel>
                                                </ft-custom-editor>

                                                <!-- <ft-toggle-editor name="isSkipSummary"
                                                                [(ngModel)]="selectedComponent.isSkipSummary"
                                                                label="Status"
                                                                title="Should this discovery skip the plan summary"
                                                                summary="Discovery will allow users to skip the planner summary step when entering discovery through this component. Useful when plans are set via external triggers or forms.">
                                                    <ft-edit-panel label="Should this discovery skip the plan summary" >
                                                        {{ selectedComponent.isSkipSummary | ftOnOffToggle }}
                                                    </ft-edit-panel>
                                                </ft-toggle-editor> -->
                                            </div>
                                        </section>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </form>
                    </ft-snapshot-form>
                </ft-permissions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    <ng-template #noData>
        <p class="mt-5 text-center">
            There is no data
        </p>
    </ng-template>
    <ng-template #sharedSettingsTooltip>
        <p>
            This setting may be shared between components, ie Planner Flow,
            Booking Bar. Changing the value here will update any other component
            that uses this setting.
        </p>
    </ng-template>
</ft-modal-wrapper>>
