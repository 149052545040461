/**
 * Created by Roman on 2015-04-28.
 */


angular.module('flipto.components.curation')
    .directive('ftCurationModal',
        ['ftModal', 'PhotoContest', 'curationModal', '$filter', 'parse', '$window', 'shareOAuth', 'fileSaver', 'IdentityService', '_', '$compile', "$rootScope", 'urlFormatter', 'scroll',
            function (ftModal, PhotoContest, curationModal, $filter, parse, $window, shareOAuth, fileSaver, identity, _, $compile, $rootScope, urlFormatter, scroll) {

                var ftAzureStoredPhoto = $filter('ftAzureStoredPhoto'),
                    translate = $filter('translate'),
                    isNowInRange = $filter("isNowInRange");

                var bulkCurationHeaderTemplate = '<div class="ft-curation-nav" ng-class="{done: isDone}"><span class="ft-curation-status"><i class="ft-icn-curation-status"></i>Curate this <span ng-if="hasPhoto">campaign submission</span><span ng-if="!hasPhoto">guest quote</span></span><a class="ft-curation-count" ft-disabled="!isDone" ng-if="total > 0" ng-click="next()"><span class="ft-count-text">{{total}} more</span><i class="ft-icn-caret-right"></i></a></div>';

                return {
                    restrict: 'E',
                    templateUrl: '/app/components/curation/curationModal.html',
                    controller: [function () {
                    }],
                    scope: {
                        'companyCode': '=',
                        'property': '=',
                        'guest': '=',
                        'socialNetworksSharing': '=',
                        'maxContentSize': '=',
                        "bulk": "=?",
                        "isShowTimeline": "=",
                        "isFinalistEmailActive": "<",
                        "onClose": "&"
                    },
                    link: function (scope, elem, attrs) {
                        scope.identity = identity;
                        var messageLimitPerNetwork = {
                            fb: 850,
                            li: 850,
                            tw: 116
                        };
                        
                        scope.uploadUrl = !!scope.guest.poststay.photo ? urlFormatter.format(
                            '/api/:propertyUuid/properties/layers/photo-advocacy/contests/photos/:photoId', { 
                            photoId: scope.guest.poststay.photo.uuid, 
                            propertyUuid: scope.property.UUID 
                        }) : undefined;
                        scope.isPiiInfoVisible = false;

                        scope.close = function () {
                            if (!!scope.onClose) scope.onClose();
                        };

                        scope.showPiiInfo = function () {
                            scope.isPiiInfoVisible = true;
                        };

                        scope.$watch("sharing.network", function (network) {
                            if (!network) return;
                            if (scope.isLinkedin(network)) {
                                scope.shareMessageLimit = messageLimitPerNetwork.li;
                            } else if (scope.isFacebook(network)) {
                                scope.shareMessageLimit = messageLimitPerNetwork.fb;
                            } else if (scope.isTwitter(network)) {
                                scope.shareMessageLimit = messageLimitPerNetwork.tw;
                            } else {
                                delete scope.shareMessageLimit;
                            }
                        });

                        /**
                         * set right content sizes
                         */
                        scope.$watchGroup(["sharing.isShowGuestQuote", "sharing.isShowPhotoCaption"], function (values) {
                            scope.quoteSize = scope.maxContentSize;
                            scope.captionSize = scope.maxContentSize;
                        });

                        /**
                         * Open image details modal
                         */
                        scope.showImageModal = function () {
                            ftModal.hide('curation').then(function () {
                                ftModal.show('curationImageDetails');
                            });
                        };

                        /**
                         * Show curation modal
                         */
                        scope.showCurationModal = function () {
                            ftModal.show('curation');
                        };

                        /**
                         * Rotate image
                         */
                        scope.changeRotateDegree = function () {
                            scope.guest.poststay.photo.degrees += 90;
                        };

                        /**
                         * Rate photo
                         * @param rating
                         */
                        scope.ratePhoto = function (rating) {
                            scope.guest.poststay.photo.internalInfo = scope.guest.poststay.photo.internalInfo || {};
                            scope.guest.poststay.photo.internalInfo['rating'] = rating;
                            PhotoContest.ratePhoto(scope.property.UUID, scope.guest.poststay.photo.uuid, rating);
                            curationModal.emit('photo-rated', [scope.guest.poststay.photo.uuid, rating]);
                        };

                        /**
                         * Rate caption
                         * @param rating
                         */
                        scope.rateCaption = function (rating) {
                            scope.guest.poststay.photo.internalInfo = scope.guest.poststay.photo.internalInfo || {};
                            scope.guest.poststay.photo.internalInfo['captionRating'] = rating;
                            PhotoContest.rateCaption(scope.property.UUID, scope.guest.poststay.photo.uuid, rating);
                            curationModal.emit('caption-rated', [scope.guest.poststay.photo.uuid, rating]);
                        };

                        /**
                         * Rate quote
                         * @param rating
                         */
                        scope.rateQuote = function (rating) {
                            scope.guest.poststay.quote.internalInfo = scope.guest.poststay.quote.internalInfo || {};
                            scope.guest.poststay.quote.internalInfo['rating'] = rating;
                            PhotoContest.rateQuote(scope.property.UUID, scope.guest.uuid, rating);
                            curationModal.emit('quote-rated', [scope.guest.uuid, rating]);
                        };

                        /**
                         * Pass photo
                         */
                        scope.pass = function () {
                            PhotoContest.pass(scope.property.UUID, scope.guest.poststay.photo.uuid)
                                .then(function () {
                                    scope.guest.poststay.photo.isFinalist = false;
                                    scope.guest.poststay.photo.isPassed = true;
                                    curationModal.emit('pass', scope.guest.poststay.photo.uuid);
                                });
                        };

                        /**
                         * Make photo a finalist
                         */
                        scope.advanceToFinal = function () {
                            if (!ensureFinalistEmailIsActive()) return false;
                            PhotoContest.advanceToFinal(scope.property.UUID, scope.guest.poststay.photo.uuid)
                                .then(function () {
                                    scope.guest.poststay.photo.isPassed = false;
                                    scope.guest.poststay.photo.isFinalist = true;
                                    curationModal.emit('finalist', scope.guest.poststay.photo.uuid);
                                });
                        };

                        scope.showReplaceModal = function () {                            
                            ftModal.hide('curation').then(function () {
                                ftModal.show('curationImageReplacement');
                            });
                        }
                        /**
                         * Replace Photo
                         */
                        scope.replacePhoto = function (data) {
                            scope.guest.poststay.photo.isUpdated = true;
                            curationModal.emit('photo-updated', scope.guest.poststay.photo.uuid);                                                        
                            ftModal.hide('curationImageReplacement').then(function () {
                                ftModal.show('curation');
                            });
                        };

                        /**
                         * Save photo details
                         */
                        scope.savePhotoDetails = function () {
                            var model = {
                                'caption': scope.guest.poststay.photo.caption,
                                'rotation': parseDegree(scope.guest.poststay.photo.degrees)
                            };
                            if (scope.guest.poststay.quote) {
                                model.quote = scope.guest.poststay.quote.notesPositive;
                            }

                            return PhotoContest.setPhotoInfo(scope.property.UUID, scope.guest.poststay.photo.uuid, model)
                                .then(function () {
                                    if (model.rotation > 0) {
                                        scope.guest.poststay.photo.isUpdated = true;
                                        scope.guest.poststay.photo.photoUrl = ftAzureStoredPhoto(scope.guest.poststay.photo, 'small');
                                        scope.guest.poststay.photo.degrees = 0;
                                    }
                                    curationModal.emit('photo-updated', scope.guest.poststay.photo.uuid);
                                })
                                .finally(function () {
                                    ftModal.show('curation');
                                });
                        };

                        /**
                         * Open sharing modal
                         * @param network
                         */
                        scope.openShareModal = function (network) {
                            scope.sharing = {};
                            ftModal.hide('curation').then(function () {
                                angular.extend(scope, {
                                    sharing: {
                                        network: network,
                                        // Background defaults to guest photo
                                        backgroundIsGuestPhoto: !!scope.guest.poststay.photo,
                                        // Only show profile pic if we have one (unverified if valid)
                                        isShowProfilePic: !!scope.guest.profileImageUrl,
                                        // Only show guest name if there is one (should always be in non-test cases)
                                        isShowGuestName: scope.guest.first && scope.guest.first.length > 0,
                                        // Default to show text if either of them is positive
                                        isShowGuestQuote: !scope.isHotelRatedNegativeQuote() && !scope.isHotelRatedNeutralQuote() && scope.hasPositiveQuote() && !scope.isCaptionBetterThanQuote(),
                                        // Default to show caption if positive
                                        isShowPhotoCaption: !scope.isHotelRatedNegativeCaption() && !scope.isHotelRatedNeutralCaption() && scope.hasCaption() && scope.isCaptionBetterThanQuote(),
                                        // Determines the landscape vs. portrait class
                                        isLandscape: scope.isLandscape(),
                                        // Determines the ft-rtl class
                                        isRtl: scope.isRtl()
                                    }
                                });
                                ftModal.show('curationShare');
                            });
                        };

                        /**
                         * Sharing' background photo watcher
                         */
                        scope.$watch('sharing.backgroundIsGuestPhoto', function (isGuestPhoto) {
                            if (angular.isDefined(isGuestPhoto)) {
                                scope.sharing.backgroundPhoto = isGuestPhoto ?
                                    ftAzureStoredPhoto(scope.guest.poststay.photo, 'small') :
                                    scope.property.BackgroundImageUrl;
                            }
                        });

                        // Returns true if the picture exists and is at least as wide as it is tall
                        scope.isLandscape = function () {
                            return scope && scope.guest && scope.guest.poststay && scope.guest.poststay.photo && parse.intOrDefault(scope.guest.poststay.photo.photoWidth) > parse.intOrDefault(scope.guest.poststay.photo.photoHeight);
                        };

                        /**
                         * Returns true if the language is a right-to-left language
                         */
                        scope.isRtl = function () {
                            return scope && scope.guest && scope.guest.isRtl && !!+scope.guest.isRtl;
                        };

                        /**
                         * Returns true if network in instagram
                         */
                        scope.isInstagram = function (network) {
                            return network && network.socialNetworkType == 5;
                        };

                        /**
                         * Returns true if network in linkedin
                         */
                        scope.isLinkedin = function (network) {
                            return network && network.socialNetworkType == 3;
                        };

                        scope.isFacebook = function (network) {
                            return network && network.socialNetworkType == 1;
                        };

                        scope.isTwitter = function (network) {
                            return network && network.socialNetworkType == 2;
                        };

                        scope.isUnknown = function (network) {
                            return network && network.socialNetworkType == 0;
                        };

                        /**
                         * Only true if caption exists and is better than the quote
                         */
                        scope.isCaptionBetterThanQuote = function () {
                            var guest = scope.guest;
                            if (!scope.hasCaption()) {
                                return false;
                            }
                            if (!scope.hasPositiveQuote()) {
                                return true;
                            }
                            var quoteRating = guest && guest.poststay && guest.poststay.quote && guest.poststay.quote.internalInfo && guest.poststay.quote.internalInfo['rating']
                                ? guest.poststay.quote.internalInfo['rating']
                                : 0;
                            var captionRating = guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.internalInfo && guest.poststay.photo.internalInfo['captionRating']
                                ? guest.poststay.photo.internalInfo['rating']
                                : 0;
                            return quoteRating < captionRating;
                        };

                        /**
                         * Returns true if quote exists, is rated and is 1
                         */
                        scope.isHotelRatedNegativeQuote = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.quote && guest.poststay.quote.internalInfo && guest.poststay.quote.internalInfo['rating'] <= 1;
                        };

                        /**
                         * Returns true if quote exists, is rated and is 2
                         */
                        scope.isHotelRatedNeutralQuote = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.quote && guest.poststay.quote.internalInfo && guest.poststay.quote.internalInfo['rating'] == 2;
                        };

                        /**
                         * Returns true if caption exists, is rated and is 1
                         */
                        scope.isHotelRatedNegativeCaption = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.internalInfo && guest.poststay.photo.internalInfo['captionRating'] <= 1;
                        };

                        /**
                         * Returns true if caption exists, is rated and is 2
                         */
                        scope.isHotelRatedNeutralCaption = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.internalInfo && guest.poststay.photo.internalInfo['captionRating'] == 2;
                        };

                        /**
                         * Returns true if guest has positive not-empty quote
                         * @returns {*|string}
                         */
                        scope.hasPositiveQuote = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.quote && guest.poststay.quote.notesPositive && parse.bool(guest.poststay.quote.isPositive);
                        };

                        scope.hasQuote = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.quote && angular.isDefined(guest.poststay.quote.notesPositive);
                        };

                        scope.isInvitee = function () {
                            var guest = scope.guest;
                            return guest && parse.bool(guest.isInvitee);
                        };

                        /**
                         * Returns true if guest has uploaded photo
                         * @returns {*|string}
                         */
                        scope.hasPhoto = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && angular.isDefined(guest.poststay.photo);
                        };

                        /**
                         * Returns true if guest has uploaded photo with caption
                         * @returns {*|string}
                         */
                        scope.hasCaption = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.caption && guest.poststay.photo.caption.length > 0;
                        };

                        /**
                         * Create share image
                         */
                        scope.createShareImage = function () {
                            var sharing = scope.sharing,
                                network = scope.sharing.network,
                                guest = scope.guest,
                                property = scope.property;

                            ftModal.hide('curationShare').then(function () {
                                ftModal.hide('curation').then(function () {
                                    ftModal.show('createImageProgress');
                                    var model = {
                                        GuestUUID: guest.uuid,
                                        SocialApplication: parse.int(network.socialNetworkType),
                                        PhotoUUID: sharing.backgroundIsGuestPhoto ? guest.poststay.photo.uuid : null,
                                        isShowPhotoCaption: !!(sharing.backgroundIsGuestPhoto && sharing.isShowPhotoCaption && !scope.isHotelRatedNegativeCaption()),
                                        isShowGuestPhoto: sharing.backgroundIsGuestPhoto,
                                        SelectedPhotoUrl: sharing.backgroundIsGuestPhoto ? ftAzureStoredPhoto(guest.poststay.photo) : property.BackgroundImageUrl,
                                        isShowGuestName: !!sharing.isShowGuestName,
                                        isShowProfilePic: !!sharing.isShowProfilePic,
                                        isShowGuestQuote: !!(sharing.isShowGuestQuote && !scope.isHotelRatedNegativeQuote() && scope.hasPositiveQuote())
                                    };
                                    PhotoContest.createCurationShareImage(
                                        property.UUID,
                                        model
                                    )
                                        .then(function (shareImageUrl) {
                                            ftModal.hide('createImageProgress')
                                                .then(function () {
                                                    sharing.shareImageUrl = shareImageUrl;
                                                    ftModal.show('shareImageCreated');
                                                });
                                        }, function () {
                                            ftModal.hide('createImageProgress').then(function () {
                                                ftModal.show('curationShare').then(function () {
                                                    $window.alert(translate('flipto.account.CurationShareError'));
                                                });
                                            });
                                        });
                                });

                            });
                        };

                        /**
                         * Download high-res image
                         */
                        scope.downloadHighResImage = function () {
                            var azureUrl = ftAzureStoredPhoto(scope.guest.poststay.photo);
                            PhotoContest.downloadShareImage(scope.property.UUID, azureUrl)
                                .then(function (data) {
                                    fileSaver.saveBlob(data.content, data.fileName);
                                });
                        };

                        /**
                         * Reprocess image from raw
                         */
                        scope.reprocessImage = function () {
                            return PhotoContest.reprocessPhoto(scope.property.UUID, scope.guest.poststay.photo.uuid)
                                .then(function () {
                                    scope.guest.poststay.photo.isUpdated = true;
                                    curationModal.emit('photo-updated', scope.guest.poststay.photo.uuid);
                                });
                        };

                        /**
                         * Download share image (i.e. instagram sharing)
                         */
                        scope.downloadShareImage = function (isStayOpen) {
                            var sharing = scope.sharing;
                            PhotoContest.downloadShareImage(scope.property.UUID, scope.sharing.shareImageUrl)
                                .then(function (data) {
                                    fileSaver.saveBlob(data.content, data.fileName);
                                    if (!isStayOpen) {
                                        sharing.network.totalShareTimes = parse.intOrDefault(sharing.network.totalShareTimes) + 1;
                                        ftModal.hide('shareImageCreated');
                                    }
                                });
                        };

                        /**
                         * Open oauth window
                         */
                        scope.openOAuth = function () {
                            var sharing = scope.sharing,
                                network = scope.sharing.network,
                                guest = scope.guest,
                                companyCode = scope.companyCode;

                            var model = {
                                companyCode: companyCode,
                                languageCode: scope.languageCode || 'en',
                                socialApp: network.socialNetworkType,
                                shareMessage: scope.sharing.message,
                                shareImageUUID: !!guest.poststay.photo ? guest.poststay.photo.uuid : null,
                                photoUUID: !!guest.poststay.photo ? guest.poststay.photo.uuid : null,
                                shareImageUrl: sharing.shareImageUrl,
                                isLandscape: sharing.isLandscape,
                                callback: 'fliptoCurationShareComplete'
                            };
                            shareOAuth.log('curation-share', { action: "open", state: model });
                            shareOAuth.tryOpenWindow(network.socialNetworkType);
                            shareOAuth.trySetLocation(network.socialNetworkType, model);
                        };

                        /*
                         * Checks if the given photo has any AI categories
                         */
                        scope.hasAiCategories = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.categories && guest.poststay.photo.ai.categories.length > 0;
                        };

                        /*
                         * Returns a comma separated list of categories generated by the AI
                         */
                        scope.getAiCategories = function () {
                            if (scope.hasAiCategories()) {
                                return _.map(scope.guest.poststay.photo.ai.categories, function (cat) {
                                    return cat.value.replace(/_+$/, "");
                                }).join(', ');
                            }
                            return null;
                        };

                        /*
                         * Checks if the given photo has any AI tags
                         */
                        scope.hasAiTags = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.tags && guest.poststay.photo.ai.tags.length > 0;
                        };

                        /*
                         * Returns a comma separated list of tags generated by the AI
                         */
                        scope.getAiTags = function () {
                            if (scope.hasAiTags()) {
                                return _.map(scope.guest.poststay.photo.ai.tags, function (tag) {
                                    return tag.value.replace(/_+$/, "");
                                }).join(', ');
                            }
                            return null;
                        };

                        /*
                         * Checks if the given photo has any AI descriptions
                         */
                        scope.hasAiDescriptions = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.captions && guest.poststay.photo.ai.captions.length > 0;
                        };

                        /*
                         * Returns the best description created by the AI
                         */
                        scope.getTopAiDescription = function () {
                            if (scope.hasAiDescriptions()) {
                                return scope.guest.poststay.photo.ai.captions[0].value;
                            }
                            return null;
                        };

                        /*
                         * Checks if the given photo has any AI detected faces
                         */
                        scope.hasAiFaces = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.faces && guest.poststay.photo.ai.faces.length > 0;
                        };

                        /*
                         * Checks if the given photo has both AI dimensions
                         */
                        scope.hasAiDimensions = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.data && guest.poststay.photo.ai.data.width && guest.poststay.photo.ai.data.height;
                        };

                        /*
                         * Checks if the given photo has any AI detected colors
                         */
                        scope.hasAiAnyColors = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.data && (guest.poststay.photo.ai.data.dominant_color_1 || guest.poststay.photo.ai.data.dominant_color_2 || guest.poststay.photo.ai.data.dominant_color_3);
                        };

                        /*
                         * Checks if the picture has any AI data yet
                         */
                        scope.hasAiData = function () {
                            var guest = scope.guest;
                            return guest && guest.poststay && guest.poststay.photo && guest.poststay.photo.ai && guest.poststay.photo.ai.data;
                        };

                        /*
                         * Checks if the photo contest is still running
                         */
                        scope.isContestRunning = function () {
                            var guest = scope.guest;
                            return !guest.poststay.photo.photoContest.ended;
                        };

                        scope.openAdvocatePage = function () {
                            var guest = scope.guest;
                            // v1 and v2 sub for this and open own details page
                            $rootScope.$broadcast("curationModal.openDetails", guest.uuid);
                        };

                        scope.isCurated = function () {
                            if (scope.hasPhoto()) {
                                var isCurated = scope.isNotReviewed(scope.guest.poststay.photo) || (scope.guest.poststay.photo.isPassed || scope.guest.poststay.photo.isFinalist);
                                isCurated = isCurated && angular.isDefined(scope.guest.poststay.photo.internalInfo) && angular.isDefined(scope.guest.poststay.photo.internalInfo['rating']);
                                if (scope.hasCaption()) {
                                    isCurated = isCurated && angular.isDefined(scope.guest.poststay.photo.internalInfo) && angular.isDefined(scope.guest.poststay.photo.internalInfo['captionRating']);
                                }
                                if (scope.hasQuote() && parse.bool(scope.guest.poststay.quote.isPositive)) {
                                    isCurated = isCurated && angular.isDefined(scope.guest.poststay.quote.internalInfo) && angular.isDefined(scope.guest.poststay.quote.internalInfo['rating']);
                                }
                                return isCurated;
                            } else if (scope.hasQuote()) {
                                return parse.bool(scope.guest.poststay.quote.isPositive) &&
                                    angular.isDefined(scope.guest.poststay.quote.internalInfo) &&
                                    angular.isDefined(scope.guest.poststay.quote.internalInfo['rating']);
                            }

                            return false;
                        };

                        scope.isNotReviewed = function (photo) {
                            return (photo.photoContest.isClosed) && !(photo.isPassed || photo.isFinalist);
                        };

                        scope.broadcastStory = function () {
                            $rootScope.$broadcast("[StoryModal].BroadcastStory", {
                                propertyUuid: scope.property.UUID,
                                storyUuid: scope.guest.uuid
                            });
                        };

                        /*bulk header*/
                        var bulkHeaderScope;
                        angular.isDefined(attrs.bulk) && scope.$watch("guest", function (guest) {
                            if (!guest) return;
                            ensureBulkScope(guest);
                            ensureBulkHeader();
                            ensureBulkProgressUpdates();
                        });
                        var becameCurated = false;
                        var deRegFn;

                        function ensureBulkScope() {
                            if (bulkHeaderScope) {
                                bulkHeaderScope.hasPhoto = scope.hasPhoto();
                                bulkHeaderScope.isDone = scope.isCurated();
                                bulkHeaderScope.total = scope.bulk;
                            } else {
                                bulkHeaderScope = angular.extend(scope.$new(true), {
                                    hasPhoto: scope.hasPhoto(),
                                    isDone: scope.isCurated(),
                                    total: scope.bulk,
                                    next: function () {
                                        curationModal.moveNext();
                                        if (!!headerTarget && headerTarget.scrollIntoView) {
                                            headerTarget.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }
                                });
                            }
                        }

                        var headerTarget;

                        function ensureBulkHeader() {
                            if (deRegFn) return;
                            deRegFn = scope.$on('modal-curation.show', function () {
                                var target1 = angular.element(arguments[1].$element[0].querySelector(".modal-body .modal-header")),
                                    target2 = angular.element(arguments[1].$element[0].querySelector(".modal-footer"));

                                if (!target1[0].querySelectorAll(".bulk-curation").length && !target2[0].querySelectorAll(".bulk-curation").length) {
                                    headerTarget = target1[0];
                                    target1.prepend($compile(bulkCurationHeaderTemplate)(bulkHeaderScope));
                                    target2.prepend($compile(bulkCurationHeaderTemplate)(bulkHeaderScope));
                                }
                            });

                        }

                        function ensureBulkProgressUpdates() {
                            curationModal.on('photo-rated', onCurationProgress);
                            curationModal.on('caption-rated', onCurationProgress);
                            curationModal.on('quote-rated', onCurationProgress);
                            curationModal.on('pass', onCurationProgress);
                            curationModal.on('finalist', onCurationProgress);
                        }

                        function noBulkProgressUpdates() {
                            curationModal.off('photo-rated', onCurationProgress);
                            curationModal.off('caption-rated', onCurationProgress);
                            curationModal.off('quote-rated', onCurationProgress);
                            curationModal.off('pass', onCurationProgress);
                            curationModal.off('finalist', onCurationProgress);
                        }

                        function onCurationProgress() {
                            var isDone = scope.isCurated();
                            if (!bulkHeaderScope.isDone && isDone) {
                                becameCurated = true;
                                curationModal.emit("curated", scope.guest.uuid);
                            }
                            bulkHeaderScope.isDone = isDone;
                        }

                        function ensureFinalistEmailIsActive() {
                            if (!scope.isFinalistEmailActive) {
                                alert("Finalist email is disabled, please enable it before curating guests.");
                                return false;
                            }
                            return true;
                        }

                        scope.$on("$destroy", function () {
                            if (deRegFn) deRegFn();
                            noBulkProgressUpdates();
                        });

                        /**
                         * Setup callback function that gets called once share process is complete
                         * @type {Function|*}
                         */
                        $window.fliptoCurationShareComplete = function (state) {
                            shareOAuth.log('curation-share', { action: "complete", state: state });
                            var sharing = scope.sharing;
                            scope.$apply(function () {
                                if (state == 0 || state == false || typeof state === "string") {
                                    $window.alert(translate('flipto.account.CurationShareError'));
                                } else if (state == 1 || state == true) {
                                    sharing.network.totalShareTimes = parse.intOrDefault(sharing.network.totalShareTimes) + 1;
                                }
                                shareOAuth.close();
                                ftModal.hide('shareImageCreated');
                            });
                        };

                        function parseDegree(degrees) {
                            var angle = degrees - (360 * Math.floor(degrees / 360));
                            return Math.floor(angle / 90);
                        }
                    }
                };
            }]);
