import { UrlUtil } from '../utils/url.util';

/** @dynamic */
export class CookieService {

  static find(...regex: RegExp[]) {
    const cookieObj = this.GetCookiesAsObject();
    const entries = Object.entries(cookieObj);
    const relevantEntries = entries.filter(([aKey, _]) => regex.some(aRegex => aRegex.test(aKey)));
    return Object.fromEntries(relevantEntries);
  }

  static put(key: string, value: string, options?: object) {
    document.cookie = CookieService.buildCookieString(key, value, options);
  }

  static delete(key: string) {
    this.put(key, null);
  }

  static get(key: string) {
    return CookieService.GetCookiesAsObject()[key];
  }

  private static lastCookieString: string;
  private static lastCookieObj: object;

  private static GetCookiesAsObject() {
    if(document.cookie === CookieService.lastCookieString){
      return CookieService.lastCookieObj;
    }

    const cookieObj = {};

    const cookieArray = document.cookie.split('; ');
    for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i];
      const equalIndex = cookie.indexOf('=');
      if (equalIndex > 0) { //ignore nameless cookies
        const cookieName = decodeURIComponent(cookie.substring(0, equalIndex));
        // the first value that is seen for a cookie is the most
        // specific one.  values for the same cookie name that
        // follow are for less specific paths.
        if (typeof cookieObj[cookieName] === "undefined") {
          cookieObj[cookieName] = decodeURIComponent(cookie.substring(equalIndex + 1));
        }
      }
    }
    this.lastCookieString = document.cookie;
    this.lastCookieObj = cookieObj;
    return cookieObj;
  }


  private static buildCookieString(name: string, val: string, opts: any) {
    let path: string;
    let expires: any;
    opts = opts || {};
    expires = opts.expires;
    path = !!opts.path ? opts.path : '/';
    if (!val) {
      expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
      val = '';
    }
    if (!!expires) {
      expires = new Date(expires);
    }
    opts.domain = UrlUtil.rootDomain();

    let str = encodeURIComponent(name) + '=' + encodeURIComponent(val);
    str += path ? ';path=' + path : '';
    str += opts.domain ? ';domain=' + opts.domain : '';
    str += expires ? ';expires=' + expires.toUTCString() : '';
    str += opts.secure ? ';secure' : '';
    str += opts.samesite ? ';samesite=' + opts.samesite : '';

    // per http://www.ietf.org/rfc/rfc2109.txt browser must allow at minimum:
    // - 300 cookies
    // - 20 cookies per unique domain
    // - 4096 bytes per cookie
    const cookieLength = str.length + 1;
    if (cookieLength > 4096) {
      // $log.warn('Cookie \'' + name +
      //   '\' possibly not set or overflowed because it was too large (' +
      //   cookieLength + ' > 4096 bytes)!');
    }

    return str;
  }

  static getCookieDomainUserId() {
      const cookie = document.cookie.match(/sa_ftid\.[a-f0-9]+=([^;]+);?/);
      if (!cookie || !cookie.length || cookie.length < 2) return;

      const match = cookie[1];
      if (!match) return;

      const split = match.split('.');
      if (!split || !(split.length > 9)) return;

      if (split[0]) {
          return split[0];
      }
  }
}
