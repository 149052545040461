import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SpacesComponent } from './spaces.component';
import { SpacesService } from './spaces.service';
import { SpacesHistory } from './spaces-history.service';
import { SharedModule } from 'shared';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { MatIconModule } from '@angular/material/icon';
import { CheckCompanyTypeDirective } from './directives/check-company-type.directive';
import { CheckSpaceTypeDirective } from './directives/check-space-type.directive';
import { InstantSearchModule } from '../../modules/instant-search/instant-search.module';
import { AccountManagementModule } from '../../modules/account-management/account-management.module';

@NgModule({
    declarations: [
        SpacesComponent,
        CheckSpaceTypeDirective,
        CheckCompanyTypeDirective
    ],
    imports: [
        MatIconModule,
        CommonModule,
        FormsModule,
        SharedModule,
        InstantSearchModule,
        UIRouterUpgradeModule.forChild({}),
        AccountManagementModule
    ],
    exports: [
        SpacesComponent,
        CheckSpaceTypeDirective,
        CheckCompanyTypeDirective
    ],
    providers: [
        SpacesService,
        SpacesHistory
    ]
})
export class SpacesModule {
}
