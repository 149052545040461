import { LocalStorageService } from './services/local-storage.service';
import { NgModule } from "@angular/core";
import { IntersectionObserverComponent } from "./dom/intersection-observer/intersection-observer.component";
import { IntersectionObservableComponent } from "./dom/intersection-observer/intersection-observable.component";
import { BackgroundImageComponent } from "./images/background-image.component";
import { MatIconModule } from "@angular/material/icon";
import { ProgressComponent } from "./forms/progress/progress.component";
import { AutofocusDirective } from "./dom/autofocus.directive";
import { ClickClassDirective } from "./dom/click-class.directive";
import { RestrictInputDirective } from "./forms/restrict-input.directive";
import { UiViewWrapperDirective } from "./utils/ui-view-wrapper.directive";
import { DisabledDirective } from "./dom/disabled.directive";
import { IsVisibleDirective } from "./dom/is-visible.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { AreasModule } from "./navigation/areas/areas.module";
import { ModalModule } from "./dialogs/modal/modal.module";
import { TooltipComponent } from "./dialogs/tooltip/tooltip.component";
import { TrapFocusDirective } from "./dialogs/trap-focus.directive";
import { LoadingModule } from "./forms/loading";
import { DateRangePickerModule } from "./forms/date-range-picker";
import { SeasonComponent } from "./components/season/season.component";
import { DownloadService } from "./services/download.service";
import { FileDownloadComponent } from "./forms/file-download/file-download.component";
import { PasswordToggleComponent } from "./forms/password-toggle/password-toggle.component";
import { ValidationChecklistModule } from "./forms/validation-checklist/validation-checklist.module";
import { FileSaverService } from "./services/fileSaver.service";
import { EditorsModule } from "./forms/editors/editors.module";
import { SnapshotModule } from "./forms/snapshot/snapshot.module";
import { UploadersModule } from "./forms/uploaders/uploaders.module";
import { ImagesModule } from "./images/images.module";
import { TreeModule } from "./modules/tree/tree.module";
import { PipesModule } from "./utils/pipes/pipes.module";
import { PrependInputDirective } from "./forms/prepend-input.directive";
import { ColorSwatchComponent } from "./forms/color-swatch/color-swatch.component";
import { StickyModule } from "./modules/sticky/sticky.module";
import { PanelsModule } from "./panels/panels.module";
import { DatePickerModule } from "./forms/date-picker/date-picker.module";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { CarouselComponent } from './components/carousel/carousel.component';
import { ListElementComponent } from './components/list-element/list-element.component';
import { RatingComponent } from './components/rating/rating.component';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { ImageFallbackDirective } from "./directives/image-fallback.directive";
import { MediaQueryDirective } from "./directives/media-query.directive";
import { TokenReplacerService } from './services/token-replacer/token-replacer.service';
import { LogoGridComponent } from './components/logo-grid/logo-grid.component';
import { SwiperModule } from 'swiper/angular';
import { ControlPanelSearchComponent } from './forms/control-panel-search/control-panel-search.component';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';
import { ClickOutsideModule } from "ng-click-outside";
import { AdvancedSearchModule } from "./modules/advanced-search/advanced-search.module";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { ScrollToTopDirective } from './directives/scroll-to-top.directive';
import { FliptoStaffOnlyDirective } from './directives/flipto-staff-only.directive';
import { FitTextDirective } from './directives/fit-text.directive';
import { CarouselModule } from './modules/carousel';


@NgModule({
    declarations: [
        IntersectionObserverComponent,
        IntersectionObservableComponent,
        ProgressComponent,
        AutofocusDirective,
        PrependInputDirective,
        ClickClassDirective,
        RestrictInputDirective,
        UiViewWrapperDirective,
        ImageFallbackDirective,
        DisabledDirective,
        IsVisibleDirective,
        ScrollIntoViewDirective,
        TooltipComponent,
        TrapFocusDirective,
        SeasonComponent,
        FileDownloadComponent,
        PasswordToggleComponent,
        ColorSwatchComponent,
        UnsubscribeComponent,
        CarouselComponent,
        ListElementComponent,
        RatingComponent,
        MediaQueryDirective,
        LogoGridComponent,
        ControlPanelSearchComponent,
        ConfirmModalComponent,
        ScrollToTopDirective,
        FliptoStaffOnlyDirective,
        FitTextDirective,
    ],
    imports: [
        FormsModule,
        CommonModule,
        AreasModule,
        ModalModule,
        LoadingModule,
        DateRangePickerModule,
        DatePickerModule,
        ValidationChecklistModule,
        MatIconModule,
        EditorsModule,
        ImagesModule,
        SnapshotModule,
        UploadersModule,
        TreeModule,
        PipesModule,
        StickyModule,
        PanelsModule,
        SwiperModule,
        ClickOutsideModule,
        AdvancedSearchModule,
        MatLegacyDialogModule,
        CarouselModule
    ],
    providers: [
        DownloadService,
        FileSaverService,
        LocalStorageService,
        TokenReplacerService
    ],
    exports: [
        ModalModule,
        AreasModule,
        IntersectionObserverComponent,
        IntersectionObservableComponent,
        BackgroundImageComponent,
        ProgressComponent,
        AutofocusDirective,
        PrependInputDirective,
        ClickClassDirective,
        RestrictInputDirective,
        UiViewWrapperDirective,
        ScrollIntoViewDirective,
        ImageFallbackDirective,
        DisabledDirective,
        IsVisibleDirective,
        TooltipComponent,
        TrapFocusDirective,
        LoadingModule,
        DateRangePickerModule,
        DatePickerModule,
        FileDownloadComponent,
        SeasonComponent,
        PasswordToggleComponent,
        ValidationChecklistModule,
        EditorsModule,
        SnapshotModule,
        UploadersModule,
        ImagesModule,
        TreeModule,
        PipesModule,
        ModalModule,
        ColorSwatchComponent,
        StickyModule,
        PanelsModule,
        UnsubscribeComponent,
        CarouselComponent,
        ListElementComponent,
        RatingComponent,
        MediaQueryDirective,
        LogoGridComponent,
        ControlPanelSearchComponent,
        ConfirmModalComponent,
        AdvancedSearchModule,
        ScrollToTopDirective,
        FliptoStaffOnlyDirective,
        FitTextDirective,
        CarouselModule,
    ]
})
export class SharedModule {
    constructor() {
    }
}
