import { SeasonRangePipe } from './pipes/season-range.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaterangePickerComponent } from "./components/daterange-picker/daterange-picker.component";
import { NgbDatepickerModule, NgbTooltipConfig, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NgbDatePipe } from "./pipes/ngb-date.pipe";
import { ClickOutsideModule } from "ng-click-outside";
import { YearRangePickerComponent } from './components/year-range-picker/year-range-picker.component';


@NgModule({
    declarations: [
        DaterangePickerComponent,
        NgbDatePipe,
        SeasonRangePipe,
        YearRangePickerComponent
    ],
    entryComponents: [
        DaterangePickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ClickOutsideModule,
        NgbDatepickerModule,
        NgbTooltipModule
    ],
    providers: [SeasonRangePipe],
    exports: [
        DaterangePickerComponent,
        YearRangePickerComponent
    ]
})
export class DateRangePickerModule {
    constructor() {
    }
}
