import { createAction, props } from '@ngrx/store';
import { Experiment } from "../models/experiment.model";
import { ExperimentStats } from "../models/experiment-stats.model";
import {
    CompletePerformanceStats,
    PerformanceStats
} from "../models/performance-stats.model";
import { DateRange } from "shared";
import { DiscoveryConversionReport, DiscoveryRangesStatistics } from 'account-hybrid/common/components/discovery/models';
import {
    DiscoveryTrafficStats,
    PlannerStats,
    DiscoveryEmailStats,
    PlannerStatsResponse
} from 'account-hybrid/common/components/discovery/interfaces';
import { DiscoveryVersioning } from '../../mission-control/models';
import { DiscoveryVersions } from '../enums/discovery-versions.enum';

export const loadExperiments = createAction('[Discovery] Load Experiments');

export const loadExperimentsFailure = createAction(
    '[Discovery] Load Experiments Failure',
    props<{ error: any }>()
);

export const loadExperimentsSuccess = createAction(
    '[Discovery]  Load Experiments Success',
    props<{ experiments: Experiment[] }>()
);

// Experiment stats

export const loadExperimentStats = createAction('[Discovery] Load Stats',
    props<{ dateRange: DateRange }>()
);

export const loadExperimentStatsFailure = createAction(
    '[Discovery] Load Stats Failure',
    props<{ error: any }>()
);

export const loadExperimentStatsSuccess = createAction(
    '[Discovery]  Load Stats Success',
    props<{ stats: ExperimentStats[] }>()
);


// Performance stats

export const loadPerformanceStats = createAction('[Discovery] Load Performance Stats',
    props<{ uuid: string }>()
);

export const loadPerformanceStatsFailure = createAction(
    '[Discovery] Load Performance Stats Failure',
    props<{ error: any }>()
);

export const loadPerformanceStatsSuccess = createAction(
    '[Discovery]  Load Performance Stats Success',
    props<{ performanceStats: PlannerStatsResponse[] }>()
);

// Complete Performance stats

export const loadCompletePerformanceStats = createAction('[Discovery] Load Complete Performance Stats',
    props<{ uuid: string }>()
);

export const loadCompletePerformanceStatsFailure = createAction(
    '[Discovery] Load Complete Performance Stats Failure',
    props<{ error: any }>()
);

export const loadCompletePerformanceStatsSuccess = createAction(
    '[Discovery]  Load Complete Performance Stats Success',
    props<{ completePerformanceStats: CompletePerformanceStats[] }>()
);

// Conversion report

export const loadDiscoveryConversionReport = createAction(
    '[Discovery] Load discovery conversion report',
    props<{ componentGroupUuid: string }>()
);

export const loadDiscoveryConversionReportFailuire = createAction(
    '[Discovery] Load discovery conversion report failed',
    props<{ error: any }>()
);

export const loadDiscoveryConversionReportSuccess = createAction(
    '[Discovery] Load discovery conversion report success',
    props<{ conversionReport: DiscoveryConversionReport }>()
);

// Date range

export const setDateRange = createAction(
    '[Discovery] Set Date Range',
    props<{ experimentDateRange: DateRange }>()
);

export const setPerformanceSectionDateRange = createAction(
    '[Discovery] Set Performance Section Date Range',
    props<{ performanceDateRange: DateRange }>()
);

// New discovery

export const loadTimelineStats = createAction(
    '[Discovery] Load Timeline Stats'
);

export const loadTimelineStatsFailure = createAction(
    '[Discovery] Load Timeline Stats Failure',
    props<{ error: any }>()
);

export const loadTimelineStatsSuccess = createAction(
    '[Discovery]  Load Timeline Stats Success',
    props<{ timelineStats: DiscoveryRangesStatistics }>()
);

export const loadPlannerStats = createAction(
    '[Discovery] Load Planner Stats',
    props<{ componentGroupUuid: string; }>()
);

export const loadPlannerStatsFailure = createAction(
    '[Discovery] Load Planner Stats Failure',
    props<{ error: any }>()
);

export const loadPlannerStatsSuccess = createAction(
    '[Discovery]  Load Planner Stats Success',
    props<{ plannerStats: PlannerStats }>()
);

export const combineTrafficAndPlannerStats = createAction(
    '[Discovery]  Combine Traffic and Planner Stats',
    props<{ plannerStats: PlannerStats }>()
);

export const loadPlannerStatsYTD = createAction(
    '[Discovery] Load YTD Planner Stats'
);

export const loadPlannerStatsYTDFailure = createAction(
    '[Discovery] Load YTD Planner Stats Failure',
    props<{ error: any }>()
);

export const loadPlannerStatsYTDSuccess = createAction(
    '[Discovery] Load YTD Planner Stats Success',
    props<{ plannerStatsYTD: PlannerStats }>()
);

export const loadTrafficStats = createAction(
    '[Discovery] Load Traffic Stats',
    props<{ uuid: string }>()
);

export const loadTrafficStatsFailure = createAction(
    '[Discovery] Load Traffic Stats Failure',
    props<{ error: any }>()
);

export const loadTrafficStatsSuccess = createAction(
    '[Discovery]  Load Traffic Stats Success',
    props<{ trafficStats: DiscoveryTrafficStats }>()
);


export const loadEmailStats = createAction(
    '[Discovery] Load Email Stats',
    props<{ uuid: string }>()
);

export const loadEmailStatsFailure = createAction(
    '[Discovery] Load Email Stats Failure',
    props<{ error: any }>()
);

export const loadEmailStatsSuccess = createAction(
    '[Discovery]  Load Email Stats Success',
    props<{ emailStats: DiscoveryEmailStats }>()
);

export const setCurrentDisplayedDiscoveryVersion = createAction(
    '[Discovery] Set Current Displayed Discovery Version',
    props<{ version: DiscoveryVersions }>()
)

export const loadDiscoveryVersioning = createAction(
    '[Discovery] Load Discovery Versioning',
    props<{ propertyUuid?: string }>()
);
export const loadDiscoveryVersioningSuccess = createAction(
    '[Discovery] Load Discovery Versioning Success',
    props<{ versioning: DiscoveryVersioning }>()
);
export const loadDiscoveryVersioningFailure = createAction(
    '[Discovery] Load Discovery Versioning Failure',
    props<{ error: any }>()
)
