import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '../date-utils';

@Pipe({
    name: 'dateToNgbDate'
})
export class DateToNgbDatePipe implements PipeTransform {
    transform(date: Date): NgbDate {
        return DateUtils.dateToNgbDate(date);
    }
}
