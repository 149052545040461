import { NgModule } from '@angular/core';
import { MainNavComponent } from './main-nav/main-nav.component';
import { SharedModule } from 'shared';
import { CommonModule } from '@angular/common';
import { AppMenuModule } from '../app-menu/app-menu.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { MenuLinkDirective } from './menu-link/menu-link.directive';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { SubMenuGroupComponent } from './sub-menu-group/sub-menu-group.component';
import { SpacesModule } from '../spaces/spaces.module';
import { CommonDeclarationsModule } from '../../modules/common-declarations/common-declarations.module';
import { AccountManagementModule } from '../../modules/account-management/account-management.module';
import { OrderModule } from 'ngx-order-pipe';


@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		SpacesModule,
		AppMenuModule,
		CommonDeclarationsModule,
		AccountManagementModule,
		UIRouterUpgradeModule.forChild({}),
		OrderModule
	],
    declarations: [
        MainNavComponent,
        MenuLinkDirective,
        SubMenuGroupComponent,
        SubMenuComponent
    ],
    exports: [
        MainNavComponent
    ]
})
export class NavModule {

}

