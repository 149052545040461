import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbCustomAdapter } from '../../../classes/custom-ngb-adapter';
import { NgbCustomFormatter } from '../../../classes/custom-ngb-formatter';


@Component({
    selector: 'ft-schedule-simple-editor',
    templateUrl: './schedule-simple-editor.component.html',
    styleUrls: ['./schedule-simple-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ScheduleSimpleEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ScheduleSimpleEditorComponent
        },
        {
            provide: NgbDateAdapter,
            useClass: NgbCustomAdapter
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbCustomFormatter
        }
    ]
})
export class ScheduleSimpleEditorComponent extends ModalEditorBase implements OnInit {

    ngOnInit(): void {
    }

    applyChanges() {
        if (this.isNgModelExist) {
            this.onChangeFn(this.value);
        }
        this.save.next({ startDate: this.value?.startDate, endDate: this.value?.endDate, rRule: this.value?.rRule });
    }
}
