import { UrlFormatter } from '@flipto/utils';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Parse } from '@flipto/shared';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';

enum RoutePurpose {
  General = 0,
  PhotoExplorer = 1,
  Landing = 2,
  Invite = 3,
  Poststay = 4,
  Trip = 5
};

@Pipe({
  name: 'cnameUrl'
})
export class CnameUrlPipe implements PipeTransform {

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment
  ) {}

  transform(value: any, purpose: number, propertySlug, params): any {
    const _domains = Parse.arrayOrDefault(value);
    if (!_domains.length) {
      return '';
    }

    const sortedDomains = _domains.slice().sort((a, b) => {
        //order by default (custom first, default last)
        let x = a.isDefault - b.isDefault;
        if (x !== 0) {
          return x;
        }

        // Then by purpose descending (specific first, general last)
        x = b.routePurposeId - a.routePurposeId;
        if (x !== 0) {
          return x;
        }

        // Then by date descending (recent first, old last)
        return Math.abs(new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
    });

    if (sortedDomains.length === 0) {
      return '';
    }

    const selectedDomain = sortedDomains[0];
    const properties = Parse.arrayOrDefault(selectedDomain.properties);
    const includeSlug = properties.length > 1;
    const includePurpose = selectedDomain.routePurpose !== purpose;
    const cnameUrl = this.createUrl(
      Parse.bool(selectedDomain.isDefault) ? selectedDomain.host + "." + this.environment.FliptoHubDomain : selectedDomain.host,
      includeSlug ? propertySlug : null, includePurpose ? this.getPurposeAsText(purpose) : null, params
    );
    return cnameUrl;
  }

  createUrl(host, slug, app, params): string {
    let segments = ['https://', host];

    if (slug) {
      segments.push('/');
      segments.push(slug);
    }

    if (app) {
      segments.push('/');
      segments.push(app);
    }

    let baseUrl = Array.prototype.join.call(segments, '');

    return UrlFormatter.appendStringToQuery(baseUrl, params);
  }

  getPurposeAsText(purpose): string {
    switch (purpose) {
      case RoutePurpose.PhotoExplorer:
        return 'photo-explorer';
      case RoutePurpose.Landing:
        return 'landing';
      case RoutePurpose.Invite:
        return 'invite';
      case RoutePurpose.Poststay:
        return 'poststay';
      case RoutePurpose.Trip:
        return 'trip';
      default:
        return '';
    }
  }
}
