<div [ftStickySpacer]="totalChanges > 0 || hasChanges"
     class="ft2-bg-striped py-5 mb-5 ft-slide-down"
     *ngIf="totalChanges > 0 || hasChanges">
    <div class="ft2-container">
        <div class="row">
            <div class="col-8 col-sm-12">
                <ng-container *ngIf="title">
                    <h2 class="h5 bold-text" *ngIf="titleVisible">{{ title }}</h2>
                </ng-container>
                <ng-container *ngIf="!title">
                    <h2 class="h5 bold-text" *ngIf="titleVisible">Confirm your changes.</h2>
                </ng-container>
                <p>
                    <ng-container *ngIf="subtitle">
                        {{ subtitle }}
                    </ng-container>
                    <ng-container *ngIf="!subtitle">
                        <ng-container *ngIf="showCounter; else singleChange">
                            <ng-container *ngIf="totalChanges > 1; else singleChange">
                                You have {{totalChanges}} changes pending.
                            </ng-container>
                        </ng-container>
                        <ng-template #singleChange>
                            You have pending changes.
                        </ng-template>
                        If everything looks good, save &amp; publish to apply
                        all changes.
                    </ng-container>
                </p>
                <ft-validation-checklist *ngIf="validationRules?.length"
                                         [(isValid)]="isValid"
                                         (isValidChange)="onIsValidChange()"
                                         [rules]="validationRules">
                </ft-validation-checklist>
            </div>
            <div class="col-4 col-sm-12 h-end">
                <button class="ft2-btn-shade -large mr-2" (click)="cancel.next(true)">
                    Cancel all
                </button>
                <button class="ft2-btn-primary -large" [disabled]="(canSave$ | async)===false" (click)="commit.next(true)" [ftLoading]="loading$">
                    Save &amp; publish
                </button>
            </div>
        </div>
    </div>
</div>
